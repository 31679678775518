<template>
<div class="w-full h-full ">
    <Modal v-show="show_message_modal" 
      v-bind:display="show_message_modal"
      :error="this.error"
      :success="this.success"
      :note="this.warning"
      z_value="z-50"
      @positiveClick="closed()"
      :positiveButton="'OK'"
      :default_width="'max-w-xl'"
      id='vote_modal'>
      <div class="w-full p-5 flex flex-col text-xl font-bold">
          {{ this.modal_message}}
      </div>
    </Modal>
    <Modal v-show="show_help_modal" 
      v-bind:display="show_help_modal"
      z_value="z-50"
      @closed="closed"
      :default_width="'max-w-md '"
      id='Help_modal'
      title="NEED HELP"
      closable>
      <div class="w-full p-5 flex flex-col text-xl font-bold space-y-1">
          <div class="w-full  border rounded shadow-md  grid grid-cols-3 h-24 cursor-pointer hover:border-theme-primary hover:border-2 hover:text-theme-primary" @click="goto('/forgot-password')">
            <Icon :source="'icons/papsi_forgot_password_icon.svg'" :width="32" class="m-auto"/>
            <span v-text="'FORGOT PASSWORD'" class="m-auto col-span-2"/>
          </div>
          <div class="w-full  border rounded shadow-md grid grid-cols-3 h-24 cursor-pointer hover:border-theme-primary hover:border-2 hover:text-theme-primary" @click="openContact">
            <Icon :source="'icons/papsi_contact_us_icon.svg'" :width="32" class="m-auto"/>
            <span v-text="'CONTACT US'" class="m-auto col-span-2"/>
          </div>
      </div>
    </Modal>
  <ContainerView height="h-full" gradient>
    <LoadingControl v-show="loading" :loading="loading"/>
    <div class="w-full h-full flex flex-row">
      <div class="w-full background-image">
        <div class="w-full flex justify-center h-full">
          <CardView :max_width="'max-w-4xl'" Logo v-show="show" :class="[{'opacity-100' : this.fade ,'opacity-0':!this.fade},'transition delay-100 static-height  sm:rounded-xl lg:rounded-xl md:rounded-xl']">
            <div class="w-full h-full flex space-y-3 mt-10 p-5 flex-col justify-center ">
                <!-- <div class="mb-10">
                  <span v-text="'MEMBERS LOGIN'" class="text-4xl font-bold"/>  
                </div> -->
                <div class="w-full">
                  <TextBoxField 
                  v-bind:value="username"
                  v-bind:placeholder="lang('username')"
                  v-bind:has_error="error_message"
                  @changed="update"
                  @enter="login"
                  id="username" 
                  type="text"/>
                </div>
                <div class="w-full">
                  <form>
                    <TextBoxField 
                    v-bind:value="password"
                    v-bind:placeholder="lang('password')"
                    v-bind:has_error="error_message" 
                    peekable
                    type="Password"
                    @changed="update"
                    @enter="login"
                    id="password"/>
                  </form>
                </div>
                <div class="text-red-500 w-full" v-text="message" v-show="error_message"/>
                <div class="relative">
                  <Button :button_text="this.loading?'':lang('login.text')" v-bind:loading="this.loading" :max_width="'max-w-full'" @click="login"/>
                </div>
                <!-- <div class="border "/> -->
                <div class="flex flex-row padding-top-links">
                  <LinkText :text="lang('sign.up.link.text')" italic :link="'/register'"/>
                  <div class="grow"/>
                  <LinkText :text="lang('need.help.link.text')" @showHelp="showHelp" :emit_triggered="'showHelp'" class="text-right"/>
                </div>   
                <!-- <div class="text-center pt-1">
                  <LinkText :text="lang('be.a.member.now')" :link="'/member/application'" italic bold/>
                </div> -->
            </div>
             <div class="w-full h-full card-gradient rounded-r-lg flex flex-col relative xs:hidden" >
                <div class="absolute w-full h-full text-center flex overflow-hidden">
                  <div class="bg-[#016b00] w-[100px] ml-[250px] mt-[250px] rounded-full absolute h-[100px]"></div>
                  <div class="bg-[#016b00] w-[100px] ml-[350px] mt-[-15px] rounded-full absolute h-[100px]"></div>
                  <div class="bg-[#016b00] w-[100px] ml-[10px] mt-[10px] rounded-full absolute h-[100px]"></div>
                  <div class="bg-[#016b00] w-[100px] ml-[390px] mt-[400px] rounded-full absolute h-[100px]"></div>
                  <div class="bg-[#016b00] w-[20px] ml-[10px] mt-[150px] rounded-full absolute h-[20px]"></div>
                  <div class="bg-[#016b00] w-[50px] ml-[50px] mt-[250px] rounded-full absolute h-[50px]"></div>
                  <div class="bg-[#016b00] w-full rounded-full h-[580px] mt-[490px]"></div>
                </div>
                <span v-text="'HI-KAMAY-ARI !'" style="text-shadow: 0.10em 0.05em black" class="text-white font-bold w-full text-center pt-16 absolute text-5xl" />
                <img src="@/assets/icons/hi.png"  class="w-full z-40 h-full self-center ml-[50px]"/>
            </div>
          </CardView>
        </div>
      </div>
    </div>
  </ContainerView>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import mixin from "@/mixin";
import User from "@/services/User";
// import OTP from "../services/OTP";
// import { RouterLink } from "vue-router";
import CardView from "@/components/controls/CardView.vue";
import ContainerView from "@/components/sections/ContainerView.vue";
import Modal from "@/components/controls/ModalControl.vue";
import TextBoxField from "@/components/controls/TextBoxField.vue";
import Button from "@/components/controls/ButtonControl.vue";
import LinkText from "@/components/controls/LinkText.vue";
import LoadingControl from "@/components/controls/LoadingControl.vue";
import Icon from "@/components/controls/Icon.vue";

export default defineComponent({
  components: {
    ContainerView,
    CardView,
    TextBoxField,
    LoadingControl,
    Button,
    LinkText,
    Modal,
    Icon
  },
  data(){
    return {
        username: '',
        password: '',
        modal_message:'',
        message:'',
        show:false,
        fade:false,
        loading:false,
        error_message:false,
        show_message_modal:false,
        show_help_modal:false,
        error:false,
        success:false,
        warning:false,
        name:''
    }
  },
  mixins: [
    mixin,
  ],
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  watch:{
    show() {
        setTimeout(() => {
            this.fade=true;
        }, 100);
    }
  },
  mounted() {
    this.show=true;
  },
  methods: {
        openContact(){
          this.show_help_modal =false;
          this.$emit('openContact');

        },
        goto(event) {
          this.$router.push(event);
        },

        getThumbnail() {
            return 'background-image: url(' + this.image + ');';
        },
        showHelp(){
          this.show_help_modal = true;
        },
        closed() {
          let that = this;
          that.show_message_modal = false;
          that.show_help_modal = false;
        },
        update(e) {
          let key = e.key;
          let that = this;
          that.error_message = false;
          that[key] = e.value;
          // this.enableButton();
        },
        authenticated(values) {
          const that = this;
          const store = that.$store;
          const state = store.state;

          let payload = {
              username: that.username,
              password: that.password,
              hash: values.hash,
          }
          User.authentication(payload,values.uuid).then(function() {
            // let data = response.data.data;  
            // that.loading = false;
             state.credentials.hash = values.hash;
            that.getUserInfo();
            state.logged = true;
          }).catch(function(error) {
            let message = error.response.data.message;
            that.modal_message = message;
            that.show_message_modal = true;
            that.error = true;
            that.error_message = true;
            that.message = message;
            that.loading = false;
          })
        },
        getUserInfo() {
          let that = this;
          const cookie = that.$cookies;
          const store = that.$store;
          //  let log = store.state.logged;
          let state = store.state;
            that.loading = true;
            User.getuserInfo(cookie.get('user')).then(function(response){
              let user_data = response.data.data;
            
              //election config
              state.election_config.date_end = user_data.election.vote_end;
              state.election_config.date_start = user_data.election.vote_start;
              state.election_config.server_time = response.timestamp;
              state.election_config.election_registered = user_data.election.registered;
              state.election_config.can_vote = user_data.election.can_vote;
              state.election_config.voted = user_data.election.voted;
              state.election_config.voting_ended = user_data.election.voting_ended;
              state.election_config.registration_start=user_data.election.reg_start;
              state.election_config.registration_end= user_data.election.reg_end;
              state.election_config.registration_started= user_data.election.reg_started;
              //credentials
              state.credentials.name = user_data.account.username;
              state.credentials.first_name=user_data.info.first_name;
              state.credentials.last_name=user_data.info.last_name;
              state.credentials.middle_name=user_data.info.middle_name;
              state.credentials.gender=user_data.info.gender;
              state.credentials.regular=user_data.info.regular;
              state.credentials.account_no = user_data.info.account_no;
              state.credentials.serial_no = user_data.info.serial_no;
              state.credentials.tin= user_data.info.tin;
              state.credentials.email = user_data.account.email;
              state.credentials.mobile = user_data.account.mobile;
              state.credentials.username = user_data.account.username;
              state.credentials.status = user_data.info.status;
              state.credentials.membership_type = user_data.info.membership_type;
              state.credentials.qr =user_data.qr;
              state.credentials.uuid = user_data.uuid;
              state.credentials.hash = cookie.get('user').hash;
              state.credentials.key = user_data.key;
              state.credentials.express = user_data.account.express;
              state.credentials.deactivated = user_data.account.deactivated;
              that.loading = false;
              that.$router.push('/Dashboard');
             
            }).catch(function(error){
              that.error = true;
              that.loading = false;
              that.show_message_modal = true;
              that.modal_message = error.response.data.message;
            });
        },
        login() {
          const that = this;
          const cookie = that.$cookies;
          const store = that.$store;
          that.loading = true;
          User.login(that.username, that.password).then(function(response) {
            let data = response.data.data;  
            
              cookie.set('user', {
                uuid:data.uuid,
                hash:data.hash,
                timestamp:data.timestamp,
              });
            store.state.credentials.hash = data.hash;
            store.state.credentials.account_no=data.account_no;
            store.state.credentials.email=data.email;
            store.state.credentials.key=data.key;
            store.state.credentials.mobile=data.mobile;
            store.state.credentials.name=data.name;
            store.state.credentials.qr=data.qr;
            store.state.credentials.serial_no=data.serial_no;
            store.state.credentials.timestamp=data.timestamp;
            store.state.credentials.username=data.username;
            store.state.credentials.uuid=data.uuid

            // console.log(store.state.credentials);
            that.authenticated(cookie.get('user'));
          }).catch(function(error) {
            let message = error.response.data.message;
            that.modal_message = message;
            that.show_message_modal = true;
            that.error = true;
            that.error_message = true;
            that.message = message;
            that.loading = false;
          })
        }
    },

});
</script>

<style scoped>
.static-height {
  height: 600px;
}

.background-image {
    width: 100%;
    margin: auto;
    place-content: center;
}
.card-gradient {
    background-image: linear-gradient(to right,#01A500, #FEE765);
}
</style>