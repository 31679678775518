<template>
    <div class="w-full flex "
    :class="[max_width, back_ground_color,height,flex_direction,{'gradient':gradient}]">
        <slot></slot>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        type:{
          type: String,
          required: false,
        },
        gradient:{
            type: Boolean
        },
        max_width:{
            type:String,
            required:false,
        },
        flex_direction:{
            type:String,
            required:false,
            default:'flex-col'
        },
        max_height:{
            type:String,
            required:false,
        },
        display:{
            type:Boolean,
            required:false,
        },
        height:{
            type:String,
            required:false,
        },
        back_ground_color:{
            type:String,
            required:false,
            default:'bg-white'
        }
    },
    data(){
        return {
            show:true,
            
        }
    },
    watch:{
        display(n) {
            setTimeout(() => {
               this.show = n;
            }, 10);
        }
    }
});
</script>
<style scoped>
.gradient{
   background-image: linear-gradient(to top,#9CA3AF, #eaebed);
}
</style>