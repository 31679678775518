<template>
<div class="h-full w-full min-h-screen" >
<img src="@/assets/images/WEB2.jpg" class="fixed h-screen w-full  "/>
<Loading v-show="loading" :loading="loading"/>
  <Container back_ground_color='md:p-20 sm:p-20 h-full xs:p-0 xs:bg-white '>
    <Modal v-show="show_selfie_modal"
        v-bind:display="show_selfie_modal"
        z_value="z-50 m-0"
        :default_width="'max-w-xl static_modal'"
        @closed="show_selfie_modal=false"
        closable
        id='vote_modal'>
          <div class="w-full p-2" v-if="show_selfie_modal">
                  <TakeAPhoto id="selfie" ref="selfie" required @changed="saveSelfie" v-if="show_selfie_modal"/>
              </div>
    </Modal>
    <Modal
    id="terms"
    v-show="show_terms_modal"
    :display="show_terms_modal"
    :z_value="'z-40'"
    :default_width="'max-w-lg'"
    >
    <!-- https://media.pafcpic.net/images/wallpaper/wallpaper-blue.jpg -->
        <div class="flex flex-col w-full space-y-2 p-2" >
          <div class="flex flex-row space-x-2 pt-6 p-2">
            <Checkbox  id="agree_ck" class="text-left mt-auto mb-auto" :checkbox_width="32" @changed="update"/>
            <div class="flex flex-wrap mt-auto mb-auto">
                <span v-text="'I agree with the'"/>
                <LinkText emit_triggered='OpenTerms' @OpenTerms="OpenTerms" class="text-theme-primary font-bold pl-1" text="End User License"></LinkText>
                <LinkText emit_triggered='OpenTerms' @OpenTerms="OpenTerms" class="text-theme-primary font-bold  pl-1 xs:pl-0" text="Agreement"></LinkText>
                <span v-text="'and'" class="pl-1 pr-1"/>
                <LinkText emit_triggered='OpenPrivacy' @OpenPrivacy="OpenPrivacy" class="text-theme-primary font-bold " text="Privacy Policy"></LinkText>
            </div>
        </div>
          <Button :button_text="'CONTINUE'" :disabled="!aggree" @clicked="proceed"/>
          <Button :button_text="'BACK'" @clicked="disagree"/>
        </div>
    </Modal>
    <Modal
    id="terms"
    v-show="show_pmes_video"
    :display="show_pmes_video"
    :z_value="'z-50'"
    :default_width="'max-w-3xl'"
    :closable="proceed_button"
    @closed="show_pmes_video=false"
    >
        <div class="flex flex-col w-full space-y-2 p-2 pb-5" v-if="show_pmes_video">
          <YouTube width='100%' height='450' :vars="{controls:0 , autoplay:1 }" src="https://www.youtube-nocookie.com/embed/9MgcNTpwkZs?controls=0" @state-change='videoStop' @ready="onReady" ref="youtube" />
          <!-- <iframe width="100%" height="550" ref="player" src="https://www.youtube-nocookie.com/embed/-0GAC6pzeI8?enablejsapi=1&autoplay=1" title="PMES" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
          <!-- v-show="proceed_button" -->
          <div class="w-full"  >
            <Button :button_text="'PROCEED'" @clicked="submit"/>
          </div>
        </div>
    </Modal>
     <Modal v-show="show_message_modal"
        v-bind:display="show_message_modal"
        :error="this.error"
        :success="this.success"
        z_value="z-50"
        @positiveClick="closed"
        :positiveButton="'OK'"
        :default_width="'max-w-xl'"
        id='vote_modal'>
       <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary">
            {{ this.modal_message}}
        </div>
    </Modal>
    <Modal v-show="show_back_to_login"
        v-bind:display="show_back_to_login"
        z_value="z-50"
        :default_width="'max-w-xl'"
        id='vote_modal'>
       <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary">
          back to login?
        </div>
         <div class="w-full flex flex-row text-xl  p-2 space-x-1"  >
            <Button :button_text="'YES'" @clicked="backtoLogin"/>
            <Button :button_text="'NO'" @clicked="this.show_back_to_login = false"/>
          </div>
    </Modal>
    <div v-show="start" class=" z-30 space-y-10 xs:space-y-0 p-2 " >
        <ConfigProvider :theme="this.token" >
          <Steps :current="this.current" size="large"  id="step" class="xs:hidden ">
            <a-step v-for="item in steps" :key="item.title" :title="item.title" @click="change(item.content)"/>
          </Steps>
        </ConfigProvider>
        <BasicInfo id="bsinfo" ref="bInfo" v-show="this.basic_info" @hasError="hasError" @numberchange="numberChange" @tinchange="tinchange" @emailchange="emailchange" @clearError="clearError" class="transition h-fit delay-100 duration-500 ease-in-out"
        v-bind:class="[{'-translate-x-0' : this.show_step_one ,'-translate-x-full':!this.show_step_one}]"/>
        <Address id="secinfo" ref="secinfo" v-show="this.address"  class="transition delay-100 duration-500 ease-in-out h-fit"
        v-bind:class="[{'-translate-x-0' : this.show_step_two ,'-translate-x-full':!this.show_step_two}]"/>
        <FamInfo id="faminfo" ref="faminfo" v-show="this.famInfo" class="transition delay-100 duration-500 ease-in-out  h-fit"
        v-bind:class="[{'-translate-x-0' : this.show_step_three ,'-translate-x-full':!this.show_step_three}]"/>
        <OtherInfo id="otherInfo" ref="otherInfo" v-show="this.otherInfo" class="transition delay-100 duration-500 ease-in-out h-fit"
        v-bind:class="[{'-translate-x-0' : this.show_step_four ,'-translate-x-full':!this.show_step_four}]"  @openCamera="openCamera"/>
        <Summary v-if="this.summary" class="transition delay-100 duration-500 ease-in-out  h-fit"
        v-bind:class="[{'-translate-x-0' : this.show_summary ,'-translate-x-full':!this.show_summary}]"/>
       
        <div class="w-full flex h-fit pb-16 pt-2 xs:space-x-1 relative">
          <div class="w-full " v-if="current>0">
              <Button :max_width="'sm:max-w-xxs md:max-w-xxs'" height='max-h-16 h-16' :button_text="'BACK'" @clicked="backStep"/>
            </div>
           <!-- <div class="w-full flex" v-if="current==0">
           <Button :max_width="'sm:max-w-xxs md:max-w-xxs'" height='max-h-16 h-16' :button_text="'BACK'" @clicked="showBackToLogin"/>
          </div> -->
            <div class="flex sm:justify-end md:justify-end w-full"  v-if="!completed">
              <Button :max_width="'sm:max-w-xxs md:max-w-xxs'" height='max-h-16 h-16' :button_text="'NEXT'" @clicked="nextStep"/>
            </div>
            <div class=" flex sm:justify-end md:justify-end w-full"  v-if="completed">
              <Button :max_width="'sm:max-w-xxs md:max-w-xxs'" height='max-h-16 h-16' :button_text="'SUBMIT'" @clicked="pmesVid"/>
            </div>
        </div>
       </div>
  </Container>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import BasicInfo from "./MembersBasicInfoView.vue";
import Address from "./MembersAddressView.vue";
import OtherInfo from "./MembersUploadingView.vue";
import FamInfo from "./MembersFamilyView.vue";
import Button from "@/components/controls/ButtonControl.vue"
import Container from '@/components/sections/ContainerView.vue';
import mixin from "@/mixin";
import LinkText from "@/components/controls/LinkText.vue";
import {Steps,ConfigProvider } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import Summary from './MembersSummaryView.vue';
import Modal from '@/components/controls/ModalControl.vue';
import Member from '@/services/Member';
import Loading from '@/components/controls/LoadingControl.vue';
import Checkbox from '@/components/controls/CheckBox.vue';
import User from '@/services/User';
import Otp from '@/services/OTP';
import YouTube from 'vue3-youtube';
import moment from 'moment';
import TakeAPhoto from '@/components/controls/TakeAPhoto.vue';
export default defineComponent({
  components:{
    Container,
    BasicInfo,
    FamInfo,
    Summary,
    Address,
    ConfigProvider,
    OtherInfo,
    Loading,
    LinkText,
    Steps,
    Button,
    Modal,
    Checkbox,
    YouTube,
    TakeAPhoto
  },
  mixins: [
    mixin,
  ],
  mounted(){
    this.proceed_button =false;
    this.show_terms_modal = true;
    this.basic_info=true;
  },
  data(){
    return{
      token:{
        colorPrimary:"#016b00"
      },
      image:'background-image: url(\'' + require('@/assets/images/WEB2.jpg') + '\'); background-repeat: no-repeat; background-position: center; width:100%; background-size: contain;',
      show_back_to_login:false,
      current:0,
      has_error:false,
      basic_info:false,
      proceed_button:false,
      show_pmes_video:false,
      show_step_one:false,
      show_step_two:false,
      show_step_three:false,
      show_step_four:false,
      show_summary:false,
      step_one_done:false,
      step_step_two:false,
      step_step_three:false,
      show_message_modal:false,
      show_selfie_modal:false,
      completed:false,
      address:false,
      aggree:false,
      start:false,
      loading:false,
      otherInfo:false,
      famInfo:false,
      show_terms_modal:false,
      summary:false,
      success:false,
      error:false,
      valid_number:false,
      valid_email:false,
      valid_tin:false,
      modal_message:'',
      errors_log:[],
      steps:[
            {
              title:"Personal Information",
              content:"bsinfo",
              active:true,
            },
            {
              title:"Address",
              content:"secinfo",
              active:true,
            },
            {
              title:"Family Information",
              content:"faminfo",
              active:true,
            },
            {
              title:"Other Information",
              content:"otherinfo",
              active:true,
            },
            {
              title:"Summary",
              content:"summary",
              active:true,
            }
          ]
    }
  },
  methods:{
     onReady() {
            this.$refs.youtube.playVideo()
           
        },
      saveSelfie(e){
          let that = this;
          let ref = that.$refs;
          let store = that.$store;
          let state = store.state;
          that.show_selfie_modal = false;
          ref.otherInfo.selfie = e.value;
          state.membership_details.snap = e.value;
      },
    videoStop(){
       if(this.$refs.youtube.getCurrentTime()>=441){
        this.proceed_button =true;
       }
    },
    disagree() {
      this.$router.push('/');
    },
    showBackToLogin(){
      this.show_back_to_login = true;
    },
    backtoLogin(){
      this.$router.push('/');
    },
    update(e){
      this.aggree = e;
    },
    pmesVid(){
      this.show_pmes_video = true;
      // console.log(this.$refs.player);
    },
    proceed(){
      let that = this;
      let store = that.$store;
      let state = store.state;
      let references = state.references;
      that.loading=true;
      Member.getReference().then(function(response) {
          let reference_data = response.data.data;

          references.branch_of_service = reference_data.branch_of_service;
          references.branches = reference_data.branches;
          references.civil_status = reference_data.civil_status;
          references.gender = reference_data.gender;
          references.ranks = reference_data.ranks;
          references.employment_status_options = reference_data.employment_status;
          that.loading=false;
          that.show_terms_modal= false;
          that.start=true;
      }).catch(function(error){
        let catch_error  = error.response.data;
        console.log(catch_error);
        that.loading=false;
      });
    },
    closed() {
      this.show_message_modal = false;
      if(this.success){
        this.$router.push('/');
      }
    },
    OpenTerms() {
      this.$emit('OpenTerms');
    },
    OpenPrivacy() {
      this.$emit('OpenPrivacy');
    },
    change(content) {
        
       if(content=='bsinfo' && this.step_one_done){
          this.current=0;
          this.basic_info=true;
          this.famInfo=false;
          this.address=false;
          this.otherInfo=false;
          this.summary=false;
        }
        if(content=='secinfo' && this.step_two_done){
          this.current=1;
          this.basic_info=false;
          this.address=true;
          this.otherInfo=false;
          this.summary=false;
          this.famInfo=false;
        }
        if(content=='faminfo' && this.step_three_done) {
          this.current=2;
          this.basic_info=false;
          this.address=false;
          this.famInfo=true;
          this.otherInfo=false;
          this.summary=false;
        }
        if(content=='otherinfo' && this.step_four_done) {
          this.current=3;
          this.basic_info=false;
          this.address=false;
          this.otherInfo=true;
          this.summary=false;
          this.famInfo=false;
          
        }
        if(content=='summary'  && this.step_three_done && this.step_two_done && this.step_one_done && this.step_four_done) {
          this.current=4;
          this.completed = true;
          this.basic_info=false;
          this.address=false;
          this.otherInfo=false;
          this.summary=true;
          this.famInfo=false;
        }
    },
    openCamera(){
      this.show_selfie_modal =true;
    },
    hasError(e){
      this.errors_log.push(e);
    },
    clearError(id) {
       for(let i = 0; i<this.errors_log.length ; i++ )
        {
          if(id == this.errors_log[i]) {
             this.errors_log.splice(i,1)
          }
        }
    },
      submit() {
        let that = this;
        let store = that.$store;
        let state = store.state.membership_details;
        that.loading = true;
            let payload = {
                ref_num:state.ref_account,
                ref_name:state.ref_name,
                br_code:state.pso,
                preferred_pso:state.pso,
                first_name:state.first_name,
                last_name:state.last_name,
                middle_name:state.middle_name,
                gender:state.gender,
                date_of_birth:state.birth_date,
                civil_status:state.civil_status,
                serial_no:state.afp_serial_number,
                tin:state.tin,
                mobile:state.mobile,
                email:state.email,
                signature:state.signature,
                id_front:state.front,
                id_back:state.back,
                father_fn:state.fafirst_name,
                father_ln:state.falast_name,
                father_mn:state.famiddle_name,
                mother_fn:state.mofirst_name,
                mother_ln:state.molast_name,
                mother_mn:state.momiddle_name,
                spouse_fn:state.spfirst_name,
                spouse_ln:state.splast_name,
                spouse_mn:state.spmiddle_name,
                address:state.resstreet_address,
                province:state.resprovince,
                city:state.rescity,
                zipcode:state.reszip_code,
                alt_address:state.altstreet_address,
                alt_province:state.altprovince,
                alt_city:state.altcity,
                alt_zipcode:state.altzip_code,
                empstatus:state.employee_status,
                suffix:state.suffix,
                brgy:state.resbrngy,
                alt_brgy:state.altbrngy,
                birth_place: state.place_of_birth,
                citizenship:state.citizenship,
                branch_service:state.branch_of_service,
                sss_no:state.sss_number,
                gsis_no:state.gsis_number,
                beneficiary:state.beneficiaries,
                schedule:state.schedule,
                share:state.sc,
                sc:state.sc_agree,
                wcp:state.wcp_agree,
                cisa:state.cisa_agree,
                snap:state.snap,
                spouse:state.spouse,
                pmes_dt:moment(Date.now()).format('YYYY-MM-DD hh:mm:ss'),
                rank:state.rank,
            }
          state.pmes_dt = moment(Date.now()).format('YYYY-MM-DD hh:mm:ss');
          that.show_pmes_video = false;
          Member.validateApplication(payload).then(function(){
            Otp.requestOtp({type:11,email:state.email,name:state.first_name}).then(function(response) {
                  state.email_pin = response.data.data.pin;
               Otp.requestOtp({type:12,mobile:state.mobile,name:state.first_name}).then(function(response) {
                     state.phone_pin = response.data.data.pin;
                    that.loading = false;
                    that.$router.push('/member/otp');
                }).catch(function(){
                    // let error_response = error;
                    that.loading = false;
                });
              }).catch(function(){
                  // let error_response = error;
                  this.loading = false;
              });
          }).catch(function(error){
                that.error = true;
                that.success = false;
                that.loading = false;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
          });

      },
    nextStep(){
        let current_step='';
        let that = this;
        let store = that.$store;
        let state = store.state.membership_details;
        
        this.checkSteps(this.steps[this.current].content);
        // console.log(this.steps[this.current].content);
      

        if(!this.completed){
            this.steps[this.current].active;
            current_step=this.steps[this.current].content;
        }
        if(current_step=='bsinfo') {
          this.basic_info=true;
          this.address=false;
          this.otherInfo=false;
          this.famInfo=false;
        }
        if(current_step=='secinfo') {
          this.basic_info=false;
          this.address=true;
          this.otherInfo=false;
          this.famInfo=false;
          this.step_one_done=true;
          
        }
        if(current_step=='faminfo') {
          this.basic_info=false;
          this.address=false;
          this.famInfo=true;
          this.otherInfo=false;
          this.step_two_done=true;

         
          
        }
        if(current_step=='otherinfo') {
          this.basic_info=false;
          this.address=false;
          this.famInfo=false;
          this.otherInfo=true;
          this.step_three_done=true;
          this.$refs.otherInfo.pushBranchRef();
          if(state.civil_status =='mr'){
            this.$refs.faminfo.pushSpouse();
          }
        }
        if(current_step=='summary') {
          this.step_four_done=true;
          this.completed=true;
          this.basic_info=false;
          this.famInfo=false;
          this.address=false;
          this.otherInfo=false;
          this.summary=true;
          this.step_four_done = true;
          
        }
        // console.log(this.current);
      window.scrollTo(0, 0);
    },
    numberChange(){
      this.valid_number = false;
    },
    emailchange(){
      this.valid_email = false;
    },
    tinchange(){
      this.valid_tin = false;
    },
    checkSteps(content){
        let ref = this.$refs;
        let store = this.$store;
        let state = store.state.membership_details;

        if(content == 'bsinfo') {
          var selectedDate = new Date(state.birth_date);
          var currentDate = new Date(Date.now());
          var diff = currentDate.getFullYear() - selectedDate.getFullYear();
          if(this.valid_email == false){
            this.validate_dups('email');
          }
          if(this.valid_number == false){
             this.validate_dups('mobile');
          }
          if(this.valid_tin == false){
             this.validate_dups('tin');
          }

          
          if(state.sss_number.length > 0 && state.gsis_number.length > 0) {
            if(this.valid_number && this.valid_email && this.valid_tin && state.first_name != null && state.last_name != null && state.middle_name != null
            && state.birth_date != null && state.place_of_birth
            && state.gender != null && state.tin != null && (state.employee_status !=null && state.employee_status != "") && state.branch_of_service != null &&
            state.civil_status !=null && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.sss_number.length == 9 && state.gsis_number.length == 8) {
              if(this.current<this.steps.length) {
                 ref.bInfo.checkLength();
                this.current++;
                
              }
            }else{
              // console.log(state);
              ref.bInfo.checkFields();
              ref.bInfo.checkLength();
              
            }
          }
          else if(state.gsis_number.length > 0) {
            if(this.valid_number && this.valid_email && this.valid_tin && state.first_name != null && state.last_name != null && state.middle_name != null
            && state.birth_date != null && state.place_of_birth
            && state.gender != null && state.tin != null && (state.employee_status !=null && state.employee_status != "") && state.branch_of_service != null &&
            state.civil_status !=null && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.gsis_number.length == 8) {
              if(this.current<this.steps.length) {
                ref.bInfo.checkLengthGSIS();
                this.current++;
                 
              }
            }else{
              // console.log(state);
              ref.bInfo.checkFields();
              ref.bInfo.checkLengthGSIS();
            }
          } 
          else if(state.sss_number.length > 0){
            if(this.valid_number && this.valid_email && this.valid_tin && state.first_name != null && state.last_name != null && state.middle_name != null
            && state.birth_date != null && state.place_of_birth
            && state.gender != null && state.tin != null && (state.employee_status !=null && state.employee_status != "") !=null && state.branch_of_service != null &&
            state.civil_status !=null && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.sss_number.length == 9) {
              if(this.current<this.steps.length) {
                ref.bInfo.checkLengthSSS();
                this.current++;
                
              }
            }else{
              // console.log(state);
              ref.bInfo.checkFields();
              ref.bInfo.checkLengthSSS();
            }
          }
          if(state.sss_number.length == 0 && state.gsis_number.length == 0){
            if(this.valid_number && this.valid_email && this.valid_tin && state.first_name != null && state.last_name != null && state.middle_name != null
            && state.birth_date != null && state.place_of_birth
            && state.gender != null && state.tin != null && (state.employee_status !=null && state.employee_status != "") !=null && state.branch_of_service != null &&
            state.civil_status !=null && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email)) {
              if(this.current<this.steps.length) {
                this.current++;
              }
            }else{
              // console.log(state);
              ref.bInfo.checkFields();
              
            }
          }
        }
        if(content == 'secinfo') {
           if(state.resstreet_address != null && state.resprovince != null && state.rescity
            && state.resbrngy != null && state.altstreet_address != null && state.altprovince != null && state.altcity != null
            && state.altbrngy != null) {
              if(this.current<this.steps.length) {
                this.current++;
              }
          }else{
            ref.secinfo.checkFields();
          }
        }
        if(content == 'faminfo'){
            // console.log();
            this.$refs.faminfo.pushBeneficiaries();
          if(state.civil_status == 'mr') {
             if((state.spfirst_name != null || state.spfirst_name != undefined ) && (state.splast_name !=null || state.splast_name !=undefined) && (state.spmiddle_name !=null || state.splast_name !=undefined) && state.beneficiaries.length > 0 ) {
              let error= 0;
                for(let i = 0; i < state.beneficiaries.length; i++){
                console.log(state.beneficiaries[i]);
                    if(state.beneficiaries[i].beneficiary_address == undefined ||
                      state.beneficiaries[i].beneficiary_mobile == undefined ||
                      state.beneficiaries[i].beneficiary_name == undefined ||
                      state.beneficiaries[i].beneficiary_relationship == undefined ){
                      ref.faminfo.checkBeneEmpty();
                      console.log(state.beneficiaries[i]);
                      error++;
                    } 
                }
                if(error > 0){
                  ref.faminfo.checkBeneEmpty();
                }else{
                  if(this.current<this.steps.length) {
                    this.current++;
                  }
                }
             }
          } else if(state.civil_status != 'mr' && state.beneficiaries.length > 0)  {
            let error= 0;
            for(let i = 0; i < state.beneficiaries.length; i++){
                if(state.beneficiaries[i].beneficiary_address == undefined ||
                  state.beneficiaries[i].beneficiary_mobile == undefined ||
                  state.beneficiaries[i].beneficiary_name == undefined ||
                  state.beneficiaries[i].beneficiary_relationship == undefined ){
                  ref.faminfo.checkBeneEmpty();
                  error++;
                } 
            }
            if(error > 0){
               ref.faminfo.checkBeneEmpty();
            }else{
              if(this.current<this.steps.length) {
                this.current++;
              }
            }
            
          }else{
            ref.faminfo.checkBeneEmpty();
          }
        }
        if(content == 'otherinfo') {
          // console.log(state.civil_status);
          if(state.sc_agree == 1){
            if(state.interview_date != null && state.schedule != null && state.pso != null && state.front  && state.back != null && state.snap != null && state.signature !=null && state.sc != null && state.cisa_agree) {
              if(this.current<this.steps.length) {
                  this.current++;
                }
            }else{
              ref.otherInfo.checkFields();
              ref.otherInfo.checkEmpty();
            }
          }else{
            if(state.interview_date != null && state.schedule != null && state.pso != null  && state.front != null && state.back != null && state.snap != null && state.signature !=null && state.cisa_agree) {
              if(this.current<this.steps.length) {
                  this.current++;
                }
            }else{
              ref.otherInfo.checkFields();
              ref.otherInfo.checkEmpty();
            }
          }
          
        }
    },
    validate_dups(id) {
          let that = this;
          let store = that.$store;
          let state = store.state.membership_details;
          let payload;
          that.loading = true;
          if(id == 'tin' && state.tin) {
              payload = {
              type:5,
              value:state.tin,
            }
          }
          if(id =='mobile' && state.mobile) {
            payload = {
              type:3,
              value:state.mobile,
            }
          }
          if(id=='email' && state.email) {
              payload = {
              type:2,
              value:state.email,
            }
          }
          User.testData(payload).then(function(){
            window.scrollTo(0, top);
            that.loading = false;
            if(id=='mobile') {
              that.valid_number = true;
              that.checkvalids();
            }
            if(id=='email') {
              that.valid_email = true;
              that.checkvalids();
            }
            if(id=='tin') {
              that.valid_tin =true;
              that.checkvalids();
            }

          }).catch(function() {
            that.$emit('scrolltop');
            window.scrollTo(0, top);
            that.loading = false;
            if(id=='mobile') {
              that.valid_number = false;
              that.checkvalids();
            }
            if(id=='email') {
              that.valid_email = false;
              that.checkvalids();
            }
            if(id=='tin') {
              that.valid_tin =false;
              that.checkvalids();
            }
          });

    },
    checkvalids() {
      let that = this;
      if(that.valid_email && that.valid_number && that.valid_tin) {
        
        that.nextStep();
      }
    },
    backStep(){
      let current_step='';

        if(this.current>0){
           this.current--;
        }

        this.steps[this.current].active;
        current_step=this.steps[this.current].content;
        this.completed = false;
        if(current_step=='bsinfo') {
          this.basic_info=true;
          this.address=false;
          this.otherInfo=false;
          this.summary =false;
          this.famInfo=false;
        }
        if(current_step=='secinfo'){
          this.basic_info=false;
          this.address=true;
          this.otherInfo=false;
          this.step_one_done=true;
          this.summary =false;
          this.famInfo=false;
        }
        if(current_step=='otherinfo'){
          this.basic_info=false;
          this.address=false;
          this.otherInfo=true;
          this.step_two_done=true;
          this.summary =false;
          this.famInfo=false;
        }
        if(current_step=='faminfo') {
          this.basic_info=false;
          this.address=false;
          this.famInfo=true;
          this.otherInfo=false;
          this.step_two_done=true;
          this.summary =false;
        }
          if(current_step=='summary') {
          this.basic_info=false;
          this.famInfo=false;
          this.address=false;
          this.otherInfo=false;
          this.summary=true;
        }
        window.scrollTo(0, 0);
    }
  },
  watch:{
    basic_info(n){
            setTimeout(() => {
                this.show_step_one = n;
        }, 10);
    },
    address(n){
            setTimeout(() => {
                this.show_step_two = n;
        }, 10);
    },
    famInfo(n){
          setTimeout(() => {
                this.show_step_three = n;
        }, 10);
    },
    otherInfo(n){
          setTimeout(() => {
                this.show_step_four = n;
        }, 10);
    },
    summary(n){
          setTimeout(() => {
                this.show_summary = n;
        }, 10);
    },
  }

})
</script>
<style scoped>
.max-height-container {
  max-height: 80% !important;
}
</style>
