<template>
    <Container height="h-20 ">
        <Loading v-show="loading" :loading="loading"/>
        <div class="h-20 bg-theme-primary w-full shadow-md items-center grid grid-cols-2 xs:grid-cols-2 fixed z-40">
            <div class="xs:papsi-logo  xs:-ml-7" >
                <img src="@/assets/images/papsi_logo_nav_icon.png" @click="this.$router.push('/dashboard')" class="h-14 xs:w-full cursor-pointer" />
            </div>
            <div class="items-center flex h-full mr-6">
                <div class="flex flex-row w-full">
                    <div class="flex-grow self-center"></div>
                    <div class="relative nav-avatar m-auto flex ">
                        <select @change="onchange" class="absolute bg-transparent text-transparent w-full max-w-container h-full cursor-pointer">
                                <option disabled selected value/>
                                <option class="text-black text-lg " value="/dashboard">Dashboard</option>
                                <option class="text-black text-lg " value="/user/profile">Profile</option>
                                <option class="text-black text-lg" value="/user/settings">Account Settings</option>
                                <option class="text-black text-lg" value="logout">Logout</option>
                        </select>
                        <div class="p-2 w-full flex flex-row items-center xs:space-x-1 space-x-2 hover:cursor-pointer text-white">
                            <span v-text="user_name"  class="text-lg"/>
                            <div class="flex bg-theme-primary rounded-full w-10 h-10 items-center">
                                <Icon :source="'images/papsi_avatar_icon.svg'" :width="40" class="m-auto"/>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Container>
</template>
<script>
import { defineComponent } from 'vue';
import Container from '@/components/sections/ContainerView.vue';
import User from '@/services/User';
import Icon from './Icon.vue';
import mixin from "@/mixin";
import Loading from "@/components/controls/LoadingControl.vue";
export default defineComponent({
    components:{
        Container,
        Icon,
        Loading
    },
    data(){
        return {
            loading:false,
            // name:'',
        }
    },
    props:{
        special:{
            type:Boolean,
            required:false,
        },
        user_name :{
            type:String,
            required:true,
        }
    },
    methods:{
        showMenu(){
         this.$emit('showMenu');
        },
        onchange(e){
            let that = this;
            let cookie = that.$cookies;
            let store = that.$store;
            let state = store.state;

            // console.log(cookie.get('user').uuid);
            // console.log(cookie.get('user').hash);
            let payload = {
                uuid:cookie.get('user').uuid,
                hash:cookie.get('user').hash
            }
            if(e.target.value == 'logout'){
                that.loading = true;
                User.logout(payload).then(function(){
                    state.logged = false;
                    that.loading= false;
                    state.credentials.hash = '';
                    cookie.remove('user');
                    that.$router.push('/');
                }).catch(function(){
                    state.logged = false;
                    that.loading= false;
                    state.credentials.hash = '';
                    cookie.remove('user');
                    that.$router.push('/');
                    
                });
            } else {
                this.$router.push(e.target.value);
            }
        }
    },
    mixins: [
        mixin,
    ],
})
</script>
<style scoped>
.papsi-logo {
    width: 250px !important;
}
</style>