<template>
    <ContainerView>
        <CardView max_width='max-w-xl' :title="lang('one.time.pin')" v-show="show"
        :class="[{'-translate-x-0 opacity-100' : this.slide ,'translate-x-80 opacity-0':!this.slide ,},'transition delay-100']">
            <span v-text="lang('otp.message')" class="italic"/>
            <TextBoxField id="otp" type="text"/>
            <ButtonControl :button_text="lang('submit')"/>
        </CardView>
    </ContainerView>
</template>
<script>
import { defineComponent } from 'vue'
import ContainerView from "@/components/sections/ContainerView.vue";
import CardView from "@/components/controls/CardView.vue";
import TextBoxField from "@/components/controls/TextBoxField.vue";
import ButtonControl from '@/components/controls/ButtonControl.vue';
import mixin from "@/mixin";

export default defineComponent({
    components:{
        ContainerView,
        CardView,
        TextBoxField,
        ButtonControl,
    },
    mounted() {
        this.show = true;
    },
    data(){
        return {
            show:false,
            slide:false,
        }
    },
    watch:{
        show() {
             setTimeout(() => {
                this.slide=true;
            }, 100);
        }
    },
    mixins: [
     mixin,
    ],
})
</script>
