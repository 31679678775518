<template>
  <Container :back_ground_color="'items-center h-full'">
      <Card max_width="max-w-6xl overflow-y-auto" :title="'SUMMARY'"  >
        <div class="flex flex-col w-full md:p-5">
            <div class="flex flex-row md:flex-row sm:flex-col xs:flex-col xs:space-x-0">
            <div class="w-full flex flex-col p-2">
                    <span v-text="'PERSONAL INFORMATION'" class="font-bold text-xl  "/>
                    <div class="grid grid-cols-2 border p-3  text-lg bg-white">
                            <span v-text="'Last Name'" class="font-bold"/>
                            <span v-text="': '+last_name"/>
                            <span v-text="'First Name'" class="font-bold"/>
                            <span v-text="': '+first_name"/>
                            <span v-text="'Middle Name'" class="font-bold"/>
                            <span v-text="': '+middle_name"/>
                            <span v-text="'Birth Date'" class="font-bold"/>
                            <span v-text="': '+birth_date"/>
                            <span v-text="'Birth Place'" class="font-bold"/>
                            <span v-text="': '+place_of_birth"/>
                            <span v-text="'Gender'" class="font-bold"/>
                            <span v-text="': '+gender"/>
                            <span v-text="'Citizenship'" class="font-bold"/>
                            <span v-text="': '+citizenship"/>
                            <span v-text="'Civil Status'" class="font-bold"/>
                            <span v-text="': '+civil_status"/>
                            <span v-text="'Branch of Service'" class="font-bold"/>
                            <span v-text="': '+branch_of_service"/>
                            <span v-text="'Employment'" class="font-bold"/>
                            <span v-text="': '+employment"/>
                            <span v-text="'Rank'" class="font-bold"/>
                            <span v-text="': '+rank"/>
                            <span v-text="'TIN'" class="font-bold"/>
                            <span v-text="': '+tin"/>
                            <span v-text="'SSS No.'" class="font-bold"/>
                            <span v-text="': '+sss"/>
                            <span v-text="'GSIS No.'" class="font-bold"/>
                            <span v-text="': '+gsis"/>
                            <span v-text="'Email Address'" class="font-bold"/>
                            <span v-text="': '+email"/>
                            <span v-text="'Mobile No.'" class="font-bold"/>
                            <span v-text="': '+mobile_number"/>
                            <span v-text="'AFP Serial No.'" class="font-bold"/>
                            <span v-text="': '+afp_serial"/>
                    </div>
                </div>
                <div class="w-full flex flex-col h-full p-2 ">
                    <span v-text="'ADDRESS'" class="font-bold text-xl  "/>
                    <div class="flex flex-col h-full border p-7 space-y-2 bg-white">
                        <span v-text="'PERMANENT ADDRESS'" class="font-bold text-lg"/>
                        <div class="grid grid-cols-2 sm:text-lg md:text-lg xs:text-md ">
                                <span v-text="'Street'" class="font-bold"/>
                                <span v-text="': '+resstreet_address"/>
                                <span v-text="'Barangay'" class="font-bold"/>
                                <span v-text="': '+resbrngy"/>
                                <span v-text="'Zip Code'" class="font-bold"/>
                                <span v-text="': '+reszip_code"/>
                                <span v-text="'Province'" class="font-bold"/>
                                <span v-text="': '+resprovince"/>
                                <span v-text="'City'" class="font-bold"/>
                                <span v-text="': '+rescity"/>
                        </div>
                        <span v-text="'SECONDARY ADDRESS'" class="font-bold text-lg"/>
                        <div class="grid grid-cols-2 sm:text-lg md:text-lg xs:text-md bg-white">
                                <span v-text="'Street'" class="font-bold"/>
                                <span v-text="': '+altstreet_address"/>
                                <span v-text="'Barangay'" class="font-bold"/>
                                <span v-text="': '+altbrngy"/>
                                <span v-text="'Zip Code'" class="font-bold"/>
                                <span v-text="': '+altzip_code"/>
                                <span v-text="'Province'" class="font-bold"/>
                                <span v-text="': '+altprovince"/>
                                <span v-text="'City'" class="font-bold"/>
                                <span v-text="': '+altcity"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full p-2">
                <span v-text="'FAMILY INFORMATION'" class="font-bold text-xl  "/>
                <div class="w-full border grid grid-cols-3 p-2 text-lg bg-white">
                        <span v-text="'Mother\'s Maiden Name'" class="font-bold"/>
                        <span v-text="': '+mo_last_name + ' '+ mo_first_name +' '+mo_middle_name" class="col-span-2" />
                        <span v-text="'Father\'s Name'" class="font-bold"/>
                        <span v-text="': '+fa_last_name +' '+ fa_first_name +' '+fa_middle_name" class="col-span-2" />
                        <span v-text="'Spouse Name'" class="font-bold"/>
                        <span v-text="': '+sp_last_name  +' '+ sp_first_name +' '+ sp_middle_name" class="col-span-2"/>
                        <span v-if="sp_last_name1 && sp_last_name1" v-text="' '" />
                        <span v-if="sp_last_name1 && sp_last_name1" v-text="': '+sp_last_name1  +' '+ sp_first_name1 +' '+ sp_middle_name1" class="col-span-2"/>
                        <span v-if="sp_last_name2 && sp_last_name2" v-text="' '" />
                        <span v-if="sp_last_name2 && sp_last_name2" v-text="': '+sp_last_name2  +' '+ sp_first_name2 +' '+ sp_middle_name2" class="col-span-2"/>
                        <span v-if="sp_last_name3 && sp_last_name3" v-text="' '" />
                        <span v-if="sp_last_name3 && sp_last_name3" v-text="': '+sp_last_name3  +' '+ sp_first_name3 +' '+ sp_middle_name3" class="col-span-2"/>
                         <span v-text="'Beneficiaries'" class="font-bold"/>
                        <div class="col-span-2 grid grid-cols-3">
                            <span v-for="(items,index) in beneficiaries" v-bind:key="items.name" v-text="beneficiaries[index].beneficiary_name != undefined ?': ' + beneficiaries[index].beneficiary_name:'' " class="col-span-3"/>
                        </div>
                </div>
            </div>
            <div class="w-full p-2">
                <span v-text="'OTHER INFORMATION'" class="font-bold text-xl  "/>
                <div class="w-full border grid grid-cols-3 p-2 text-lg bg-white">
                        <span v-text="'Interview PSO Date & Time'" class="font-bold"/>
                        <span v-text="': '+interview_date +' at ' +pso" class="col-span-2" />
                        <span v-text="'Referral Name'" class="font-bold"/>
                        <span v-text="': '+ref_name" class="col-span-2" />
                        <span v-text="'Referral Acct/Serial No.'" class="font-bold" />
                        <span v-text="': '+ref_account" />
                </div>
            </div>
        </div>
      </Card>
  </Container>  
</template>
<script>
import { defineComponent } from 'vue'
import Container from '@/components/sections/ContainerView.vue';
import Card from '@/components/controls/CardView.vue';
// import TextField from '@/components/controls/TextBoxField.vue';
import mixin from "@/mixin";

export default defineComponent({
  components:{
    Container,
    Card,
  },
  mounted(){
     let store = this.$store;
     let state = store.state.membership_details;

      this.interview_date = state.interview_date;
      this.pso = state.pso_text;
      this.first_name = state.first_name;
      this.last_name = state.last_name;
      this.middle_name = state.middle_name;
      this.rank = state.ranks_text;
      this.birth_date = state.birth_date;
      this.place_of_birth = state.place_of_birth;
      this.gender = state.gender_text;
      this.civil_status = state.civil_status_text;
      this.afp_serial = state.afp_serial_number;
      this.branch_of_service = state.branch_of_service_text;
      this.employment = state.employee_status_text;
      this.tin = state.tin;
      this.mobile_number = state.mobile;
      this.email = state.email
      this.mo_first_name = state.mofirst_name;
      this.mo_last_name = state.molast_name;
      this.mo_middle_name = state.momiddle_name;  
      this.fa_first_name = state.fafirst_name;
      this.fa_last_name = state.falast_name;
      this.fa_middle_name = state.famiddle_name; 
      this.sp_first_name = state.spfirst_name;
      this.sp_last_name = state.splast_name;
      this.sp_middle_name = state.spmiddle_name; 
      this.sp_first_name1 = state.spfirst_name2;
      this.sp_last_name1 = state.splast_name2;
      this.sp_middle_name1 = state.spmiddle_name2; 
      this.sp_first_name2 = state.spfirst_name3;
      this.sp_last_name2 = state.splast_name3;
      this.sp_middle_name2 = state.spmiddle_name3; 
      this.sp_first_name3 = state.spfirst_name4;
      this.sp_last_name3 = state.splast_name4;
      this.sp_middle_name3 = state.spmiddle_name4; 
      this.resstreet_address = state.resstreet_address;
      this.reszip_code = state.reszip_code;
      this.resprovince = state.resprovince;
      this.rescity = state.rescity;
      this.resbrngy = state.resbrngy;
      this.altstreet_address = state.altstreet_address;
      this.altzip_code = state.altzip_code;
      this.altprovince = state.altprovince;
      this.altcity = state.altcity;
      this.altbrngy = state.altbrngy;
      this.signature = state.signature;
      this.front = state.front;
      this.back = state.back;
      this.ref_account = state.ref_account;
      this.ref_name = state.ref_name;
      this.sss = state.sss_number;
      this.gsis = state.gsis_number;
      this.citizenship = state.citizenship;
      this.beneficiaries = state.beneficiaries;
      // console.log(state);
  },
  data(){
    return {
        ref_account:'',
        ref_name:'',
        resstreet_address:'',
        reszip_code:'',
        resprovince:'',
        rescity:'',
        resbrngy:'',
        altstreet_address:'',
        altzip_code:'',
        altprovince:'',
        altcity:'',
        altbrngy:'',
        first_name: '',
        last_name:'',
        middle_name:'',
        afp_serial:'',
        rank:'',
        birth_date:'',
        place_of_birth:'',
        gender:'',
        civil_status:'',
        tin:'',
        mobile_number:'',
        email:'',
        branch_of_service:'',
        employment:'',
        sp_first_name:'',
        sp_last_name:'',
        sp_middle_name:'',
        sp_first_name1:'',
        sp_last_name1:'',
        sp_middle_name1:'',
        sp_first_name2:'',
        sp_last_name2:'',
        sp_middle_name2:'',
        sp_first_name3:'',
        sp_last_name3:'',
        sp_middle_name3:'',
        mo_first_name:'',
        mo_last_name:'',
        mo_middle_name:'',
        fa_first_name:'',
        fa_last_name:'',
        fa_middle_name:'',
        beneficiaries:[],
        pso:'',
        interview_date:'',
        fron:'',
        back:'',
        signature:'', 
        citizenship:'',
        sss:'',
        gsis:''
    }
  },
   mixins: [
    mixin,
  ],
})
</script>
<style scoped>

</style>
