<template>
    <div class="text-theme-primary cursor-pointer hover:underline" :class="[{'font-bold': bold}, {'italic':italic},text_size]" @click="clicked">
        <span v-text="text"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props:{
        text: {
            type:String,
            required:true,
        },
        italic: {
            type:Boolean,
            required:false
        },
        bold:{
            type:Boolean,
            required:false
        },
        text_size:{
            type:String,
            required:false,
        },
        link:{
            type:String,
            required:false
        },
        emit_triggered:{
            type:String,
            required:false
        }
    },
    methods:{
        clicked() {
            if(this.link) {
                this.$router.push(this.link);
            } 
            if(this.emit_triggered)
            {
                this.$emit(this.emit_triggered);
            }
        }
    }
    
})
</script>
<style scoped>

</style>