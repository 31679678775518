<template>
  <ContainerView class="flex w-full h-full" gradient>
    <Modal v-show="show_identifier" 
      v-bind:display="show_identifier"
      @closed="remove"
      :default_width="'max-w-2xl'"
      :z_value="'z-50'"
      id='vote_modal'
      >
      <div class="w-full flex flex-col p-2 space-y-2">
          <span class="self-start pb-4 font-bold text-2xl" v-text="'VERIFY YOUR ACCOUNT'"/>
          <div class="flex flex-col w-full">
            <div class="flex w-45 grow-0">
                <div class="rounded-full flex border-4 bg-theme-primary border-theme-secondary  m-auto w-40 h-40">
                    <Icon id="pencil" class="self-center m-auto" source="icons/papsi_profile.svg" :width="100" />
                </div>
            </div>
            <div class="pt-2 w-full flex flex-col grow text-left ">
                <div class="flex flex-col ">
                  <div class="pb-6 text-sm">
                    <div class="border border-gray-400 bg-white flex flex-row border-b-0">
                        <div class="p-2 font-bold contact-w border-r w-full border-r-gray-400 uppercase">USERNAME</div>
                        <div class="p-2 flex-grow text-gray-500 truncate w-full" v-text="name"></div>
                    </div>
                    <div class="border border-gray-400 bg-white flex flex-row border-b-0">
                        <div class="p-2 font-bold contact-w border-r w-full border-r-gray-400 uppercase">EMAIL.</div>
                        <div class="p-2 flex-grow text-gray-500 truncate w-full" v-text="email"></div>
                    </div>
                    <div class="border border-gray-400 bg-white flex flex-row ">
                        <div class="p-2 font-bold contact-w border-r w-full border-r-gray-400 uppercase">MOBILE NO.</div>
                        <div class="p-2 flex-grow text-gray-500 truncate w-full" v-text="mobile"></div>
                    </div>
                </div>
                </div>
            </div>
          </div>
          <Button @clicked="proceed" :button_text="lang('PROCEED')"/>
          <Button @clicked="remove" :theme="'bg-red-600'" :button_text="lang('NOT ME')"/>
      </div>
    </Modal>
     <Modal v-show="show_otp_modal" 
      v-bind:display="show_otp_modal"
      @positiveClick="submitOtp"
      :positiveButton="'OK'"
      :z_value="'z-40'"
      :default_width="'max-w-xl'"
      id='otp_modal'>
      <div class="pb-4 text-sm italic text-left p-2">
            Please enter the one time pin we have sent to your email or mobile number so that we can verify your identity.
        </div>
        <div class="pb-2 font-bold uppercase text-left p-2">
          Enter OTP
        </div>
        <div class="pb-2 font-bold uppercase text-left p-2">
            <Textbox @changed="otpUpdate" id="otp" type="text"/>
        </div>
        <div class="pb-2 font-bold uppercase text-left p-2" v-show="show_resend_otp">
          <LinkText emit_triggered='resendOtp' @resendOtp="resendOtp" class="text-theme-primary font-bold" text="Resend"/>
        </div>
    </Modal>
    <Modal v-show="show_message_modal" 
      v-bind:display="show_message_modal"
      :error="error"
      :success="success"
      :note="warning" 
      @positiveClick="remove"
      :positiveButton="'OK'"
      :z_value="'z-50'"
      :default_width="'max-w-xl'"
      id='vote_modal'>
      <div class="w-full p-5 flex flex-col text-xl font-bold">
          {{ this.modal_message}}
      </div>
    </Modal>
    <LoadingControl v-show="loading" :loading="loading"/>
    <CardView  max_width="max-w-xl p-2 m-auto"  v-show="show"
        :class="[{'-translate-x-0 opacity-100' : this.fade ,'-translate-y-full opacity-0':!this.fade ,},'transition delay-100']">
        <div>
          <div class="text-base flex flex-col">
              <img src="https://media.pafcpic.net/images/logo/pafcpic-nav.png" class="pb-2 w-full" />
              <span class="text-theme-primary font-bold text-xl pb-2">Forgot Password?</span>
              Please enter any of the following details.
          </div>
            <Textbox type="text" 
              id="forgot_value" 
              class="mb-4"
              uppercase
              @changed="changed" 
              v-bind:value="forgot_value"
              placeholder="Username, email, mobile, serial or acct no."
              required />
            <div class="pb-2 flex justify-center">
              <div>
                <VueRecaptcha @verify="captchaVerify" 
                    @expired="resetCaptcha"
                    v-bind:sitekey="key" 
                    size="32"  class="w-full"/>
              </div>
            </div>
            <Button @clicked="submit" v-bind:disabled="disable_button" rounded :button_text="lang('CONTINUE')"/>
            <Button @clicked="back" rounded class="mt-2" :button_text="lang('BACK')"/>
          </div>
    </CardView>
  </ContainerView>
</template>

<script>
import { defineComponent } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import Button from "@/components/controls/ButtonControl.vue";
import Modal from "@/components/controls/ModalControl.vue";
import Textbox from "@/components/controls/TextBoxField.vue";
import ContainerView from "@/components/sections/ContainerView.vue";
import User from  "@/services/User";
import mixin from "@/mixin";
import OTP from "@/services/OTP";
import Icon from "@/components/controls/Icon.vue";
import LoadingControl from "@/components/controls/LoadingControl.vue";
import CardView from "@/components/controls/CardView.vue";
import LinkText from "@/components/controls/LinkText";
export default defineComponent({
  mixins: [
    mixin,
  ],
  components:{
    LoadingControl,
    ContainerView,
    VueRecaptcha,
    CardView,
    LinkText,
    Textbox,
    Button,
    Modal,
    Icon,
  },
  mounted() {
      this.show=true;
  },
  data(){
    return {
        key:'6LdTAAQhAAAAAApdt61xDVJaAUU5Cv6opGbLJFFT',
        forgot_value:'',
        captcha:false,
        disable_button:true,
        show_identifier:false,
        show_message_modal:false,
        show_resend_otp:false,
        show_otp_modal:false,
        name:'',
        pin:'',
        otp:'',
        timerCount:0,
        timerEnabled:false,
        show:false,
        loading:false,
        email:'',
        modal_message:'',
        mobile:'',
        error:false,
        success:false,
        warning:false,
        fade:false,
    }
  },
  methods:{
    changed(e){
        let key = e.key;
        let that = this;
        that[key] = e.value;
        that.enableButton();
    },
    back(){
      this.$router.push('/');
    },
    otpUpdate(e) {
      let that = this;
      that.otp = e.value;
    },
    submitOtp() {
      let that = this;
      that.loading =true;
      OTP.validate({otp:that.otp,type:1,email:that.email,mobile:that.mobile}).then(function(){
        that.show_otp_modal= false;
        that.otp = '';
        that.$router.push('/forgot-password/update');
      }).catch(function(error) {
        that.error = true;
        that.loading = false;
        that.show_message_modal= true;
        that.modal_message = error.response.data.message;
      });
    },
    resendOtp() {
        let that = this;
        that.loading = true;
        that.show_resend_otp = false;
        that.timerEnabled= true;
        that.timerCount = 120;
        OTP.requestOtp(
            {
              type:1, 
              email:that.email, 
              mobile:that.mobile, 
              name :that.name, 
              pin:that.pin,
            }
          ).then(function(response) {
              that.pin = response.data.data.pin;
              that.loading= false;
              that.show_otp_modal = true;
          }).catch(function() {
              that.loading= false;
          });
      },
    submit() {
      let that = this;
      const store = this.$store;
      const state = store.state.forgot_password_payload;
      that.loading = true;
      User.forgotPassword(that.forgot_value).then(function(response){
            let user_data = response.data.data;
            // console.log(user_data);
            that.name = user_data.username;
            that.email = user_data.email;
            that.mobile = user_data.mobile;
            that.show_identifier = true;
            state.uuid=user_data.uuid;
            state.hash=user_data.hash;
            that.loading = false;
      }).catch(function(error){
          that.error = true;
          that.show_message_modal= true;
          that.modal_message = error.response.data.message;
          that.loading = false;
      });
    },
    proceed() {
      let that = this;
      that.loading = true;
      let payload = {
        email:that.email,
        mobile:that.mobile,
        type:1,
        name:that.name,
      }
      that.show_identifier=false;
      OTP.requestOtp(payload).then(function() {
          that.timerEnabled= true;
          that.timerCount = 120;
          that.loading = false;
          that.show_otp_modal = true;
          // that.$router.push('/forgot-password/update');
      }).catch(function(error){
          that.error = true;
          that.show_message_modal= true;
          that.modal_message = error.response.data.message;
          that.loading = false;
      });
      // console.log('proceed');
    },
    remove(){
      this.show_message_modal = false;
      this.show_identifier = false;
    },
    captchaVerify() {
        // console.log('error');
        this.captcha = true;
        this.enableButton();
    },
    resetCaptcha(){
        this.captcha = false;
        this.enableButton();
    },
    enableButton() {
      if(this.forgot_value !='' && this.captcha) {
         this.disable_button = false;
      }else{
        this.disable_button = true;
      }
    }
  },
   watch:{
    show() {
          setTimeout(() => {
            this.fade=true;
        }, 100);
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          this.show_resend_otp = false;
          setTimeout(() => {
            this.timerCount = this.timerCount - 1;
            // console.log(this.timerCount);
          }, 1000);
        } else {
          this.show_resend_otp = true;
          this.timerEnabled = false; // run your function here
        }
      },
      immediate: true
    },
   }
});
</script>
<style scoped>
.contact-w {
    width: 100px !important;
    min-width: 120px !important;
}
</style>