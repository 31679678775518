<template>
<ContainerView class="h-screen" gradient>
    <Modal v-show="this.show_message_modal" 
    v-bind:display="this.show_message_modal"
    :error="this.error"
    :success="this.success"
    z_value="z-50"
    @positiveClick="closed"
    :positiveButton="'OK'"
    :default_width="'max-w-xl'"
    id='vote_modal'>
    <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary">
    {{ this.modal_message}}
    </div>
    </Modal>
    <Modal v-show="show_otp_modal" 
      v-bind:display="show_otp_modal"
      @positiveClick="submitOtp"
      :positiveButton="'OK'"
      :z_value="'z-40'"
      :default_width="'max-w-xl'"
      id='otp_modal'>
     <div class="pb-4 text-sm italic text-left p-2">
          Please enter the one time pin we have sent to your email or mobile number so that we can verify your identity.
      </div>
      <div class="pb-2 font-bold uppercase text-left p-2">
         Enter OTP
      </div>
       <div class="pb-2 font-bold uppercase text-left p-2">
          <TextBoxField @changed="otpUpdate" id="otp" type="text"/>
      </div>
      <div class="pb-2 font-bold uppercase text-left p-2" v-show="show_resend_otp">
         <LinkText emit_triggered='resendOtp' @resendOtp="resendOtp" class="text-theme-primary font-bold" text="Resend"/>
      </div>
    </Modal>
    <LoadingControl v-show="loading" :loading="loading"/>
    <ContainerView height="xs:h-fit h-full mt-5 xs:p-2" gradient>
        <CardView max_width="max-w-5xl xs:mb-16 m-auto" :title="lang('create.an.account')" v-show="show"
        :class="[{'-translate-x-0 opacity-100' : this.fade ,'-translate-y-full opacity-0':!this.fade ,},'transition delay-100']">
            <div class="w-full flex flex-col p-5 space-y-1 ">
                <div class="flex flex-row w-full space-x-2 xs:flex-col xs:space-x-0">
                    <TextBoxField uppercase required @changed="registerFields" id="last_name" type="text" placeholder="Last Name"/>
                    <TextBoxField uppercase required @changed="registerFields" id="first_name" type="text" placeholder="First Name"/>
                    <TextBoxField uppercase @changed="registerFields" id="middle_name" type="text" placeholder="Middle Name"/>
                </div>
                <div class="flex flex-row space-x-2 xs:flex-colxs:space-x-0">
                    <TextBoxField uppercase  @changed="registerFields" :max_length="7" id="account_number" type="text" placeholder="Account No."/>
                    <TextBoxField uppercase  @changed="registerFields" :max_length="7" id="serial_number" type="text" placeholder="Serial No."/>
                </div>
                <TextBoxField @changed="registerFields" ref="tin"  id="tin" type="number" placeholder="TIN"/>
                <div class="flex flex-col space-y-1">
                    <TextBoxField @changed="registerFields" id="email" type="text" placeholder="Email" />
                    <TextBoxField @changed="registerFields" :max_length="9" id="mobile_number" type="mobile" placeholder="Mobile no."/>
                    <div class="text-md flex text-start pt-1 text-gray-400 w-full">
                        <span v-text="lang('username.instructions')"/>
                    </div> 
                    <TextBoxField  required @changed="registerFields" id="username" type="text" placeholder="Username"/>
                    <div class="text-md flex text-start pt-1 text-gray-400 w-full">
                        <span v-text="lang('password.instructions')"/>
                    </div>
                    <form class="space-y-1">
                        <TextBoxField required strength peekable @changed="registerFields" id="password" type="Password" placeholder="Password"/>
                        <TextBoxField required peekable @changed="registerFields" id="confirm_password" type="Password" placeholder="Confirm Password"/>
                    </form>
                </div>  
                <div>
                    <div class="flex flex-row space-x-2 pt-2">
                        <Checkbox id="agree_ck" class="text-left" @changed="aggree"/>
                        <div class="flex flex-row space-x-2 mt-auto mb-auto">
                            <span v-text="'I agree with the'"/>
                            <LinkText emit_triggered='OpenTerms' @OpenTerms="OpenTerms" class="text-theme-primary font-bold" text="End User License Agreement"></LinkText>
                            <span v-text="'and'"/> 
                            <LinkText emit_triggered='OpenPrivacy' @OpenPrivacy="OpenPrivacy" class="text-theme-primary font-bold" text="'Privacy Policy'"></LinkText>
                        </div>
                    </div>
                    <div class="w-full flex justify-center p-2">
                    <VueRecaptcha v-bind:sitekey="key"  @verify="captchaVerify" />
                    </div>
                    <Button :button_text="'REGISTER'" @clicked="register" :disabled="this.reg_button_disable"/>
                    <div class="flex-grow text-center p-1">
                    <LinkText :text="lang('already.have.an.account')" bold link='/' text_size='text-md'/>
                    </div>
                </div>
            </div>
        </CardView>
    </ContainerView>
</ContainerView>
</template>
<script>
import { defineComponent } from 'vue'
import User from "@/services/User";
import TextBoxField from "@/components/controls/TextBoxField.vue";
import ContainerView from "@/components/sections/ContainerView.vue";
import CardView from "@/components/controls/CardView.vue";
import Checkbox from "@/components/controls/CheckBox.vue";
import LinkText from "@/components/controls/LinkText.vue";
import Button from '@/components/controls/ButtonControl.vue';
import { VueRecaptcha } from "vue-recaptcha";
import Otp from '@/services/OTP';
import Modal from '@/components/controls/ModalControl.vue';
import LoadingControl from '@/components/controls/LoadingControl.vue';
import mixin from "@/mixin";


export default defineComponent({
    components:{
        TextBoxField,
        ContainerView,
        CardView,
        Checkbox,
        LinkText,
        VueRecaptcha,
        Modal,
        LoadingControl,
        Button
    },
    data(){
        return{
            error:false,
            success:false,
            warning:false,
            key:'6LdTAAQhAAAAAApdt61xDVJaAUU5Cv6opGbLJFFT',
            show:false,
            fade:false,
            otp:'',
            reg_button_disable: true,
            show_message_modal:false,
            show_otp_modal:false,
            modal_message:'',
            loading:false,
            show_resend_otp:false,
            timerEnabled:false,
            timerCount:0,
            reg_info:{
                last_name:'',
                middle_name:'',
                first_name:'',
                account_number:'',
                serial_number:'',
                email:'',
                mobile_number:'',
                username:'',
                password:'',
                confirm_password:'',
                aggree:false,
                score:0,
                pin:'',
                tin:'',
                captcha:false,
            },
        }
        
    },
    mixins: [
     mixin,
    ],
    mounted() {
        // this.timerEnabled = true;
        // this.timerCount = 120;
        // this.show_otp_modal = true;
        this.show=true;
    },
    methods:{
        closed() {
            this.show_message_modal = false;
            if(this.success) {
                this.success=false;
                this.$router.push('/');
            }
            
        },
        OpenTerms() {
          this.$emit('OpenTerms');
        },
        OpenPrivacy() {
          this.$emit('OpenPrivacy');
        },
        otpUpdate(e) {
            let that = this;
            that.otp = e.value;
        },
        clicked() {
         this.$router.push('/register/validate');
        },
        captchaVerify() {
            this.reg_info.captcha = true;
            this.enableButton();
        },
        enableButton(){
            let that = this;
            if(that.reg_info.first_name != '' && 
            that.reg_info.last_name != '' && 
            that.reg_info.username != '' &&
            that.reg_info.password != '' &&
            that.reg_info.confirm_password != '' &&
            (that.reg_info.email != '' ||
            that.reg_info.mobile_number != '') &&
            (that.reg_info.account_number != '' || 
            that.reg_info.serial_number != '') &&
            that.reg_info.aggree && that.reg_info.captcha) {
                that.reg_button_disable=false; 
            } else {
              that.reg_button_disable=true;
            }
        },
        register() {
            let payload;
            let that = this;
            that.loading = true;
            that.show_registration_modal = false;
            // this.show_poll_modal = true;
            payload = {
                first_name:that.reg_info.first_name, 
                last_name:that.reg_info.last_name, 
                middle_name:that.reg_info.middle_name, 
                account_no:that.reg_info.account_number, 
                serial_no:that.reg_info.serial_number, 
                email:that.reg_info.email, 
                mobile:that.reg_info.mobile_number ==''?'':'+63'+that.reg_info.mobile_number, 
                username:that.reg_info.username,
                password1:that.reg_info.password, 
                password2:that.reg_info.confirm_password
            }
            User.signup(payload).then(function() {
             Otp.requestOtp({type:2,email:payload.email,mobile:payload.mobile,name:payload.first_name}).then(function(response) {
                that.reg_info.pin = response.data.data.pin;
                that.loading = false;
                that.timerEnabled= true;
                that.timerCount = 120;
                that.show_otp_modal=true;
              }).catch(function(error){
                that.loading= false;
                that.error = true;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
              });
            }).catch(function(error) {
                that.loading= false;
                that.error = true;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
            });
      },
      resendOtp() {
        let that = this;
        that.loading = true;
        that.show_resend_otp = false;
        that.timerEnabled= true;
        that.timerCount = 120;
        Otp.requestOtp(
            {
              type:2, 
              email:that.reg_info.email, 
              mobile:that.reg_info.mobile_number ==''?'':'+63'+that.reg_info.mobile_number, 
              name :that.reg_info.first_name, 
              pin:that.reg_info.pin,
            }
          ).then(function(response) {
              that.reg_info.pin = response.data.data.pin;
              that.loading= false;
              that.show_otp_modal = true;
              
          }).catch(function() {
              that.loading= false;
              that.modal_type = 1;
          });
      },
      createUser() {
        let payload;
        let that = this;

        payload = {
           first_name:that.reg_info.first_name, 
           last_name:that.reg_info.last_name, 
           middle_name:that.reg_info.middle_name, 
           account_no:that.reg_info.account_number, 
           serial_no:that.reg_info.serial_number, 
           email:that.reg_info.email, 
           mobile:that.reg_info.mobile_number ==''?'':'+63'+that.reg_info.mobile_number, 
           username:that.reg_info.username,
           password1:that.reg_info.password, 
           password2:that.reg_info.confirm_password,
           tin:that.reg_info.tin
        }
        User.createUser(payload).then(function() {
            that.loading= false;
            that.success = true;
            that.modal_message = "CONGRATULATIONS YOU HAVE SUCCESSFULLY REGISTERED"
            that.show_message_modal= true;
        }).catch(function(error) {
          that.loading= false;
          that.modal_type = 2;
          that.error = true;
          that.show_message_modal = true;
          that.modal_message = error.response.data.message;
        });
      },
    submitOtp() {
        let that = this;
        that.loading =true;
        Otp.validate({otp:that.otp,type:2,email:that.reg_info.email,mobile:that.reg_info.mobile_number ==''?'':'+63'+that.reg_info.mobile_number}).then(function(){
          that.show_otp_modal= false;

          that.createUser();
          that.otp = '';
        }).catch(function(error) {
          that.error = true;
          that.show_message_modal= true;
          that.modal_message = error.response.data.message;
          that.loading = false;
        });
      },
     aggree(n) {
        let that = this;
        that.reg_info.aggree = n;
        this.enableButton();
      },
      registerFields(e){
        let that = this;
        let obj = that.reg_info;
        obj[e.key] = e.value;
        this.enableButton();
      },
    },
    watch:{
        show() {
             setTimeout(() => {
                this.fade=true;
            }, 100);
        },
        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled) {
                this.show_resend_otp = false;
                setTimeout(() => {
                    this.timerCount = this.timerCount - 1;
                    // console.log(this.timerCount);
                }, 1000);
                } else {
                    this.show_resend_otp = true;
                    this.timerEnabled = false; // run your function here
                }
            },
            immediate: true
        },
    }
})
</script>
