<template>
 <Container back_ground_color='items-center h-fit '>
      <Card max_width="max-w-7xl p-2 relative">
          <div class="w-full flex flex-col p-4 space-y-2 ">
          <div class="w-full flex flex-col space-y-2">

            <div class="flex  text-lg font-bold mb-1  ">
              UPLOAD ID
            </div>
            <div class="border border-gray-300 rounded shadow flex flex-col">
              <div class="flex flex-row md:flex-row sm:flex-col xs:flex-col xs:space-x-0">
              <div class=" p-4 my-2 w-full flex flex-col">
              <div class="mb-2 self-center font-bold  ">Upload an image of your ID (FRONT) 25MB</div>
                <div class="self-center">
                  <UploadFile id="front_id"   ref="frontImage"  :componentId="'front_id'" required @changed="update"/>
                </div>
              </div>
              <div class=" p-4 my-2 w-full flex flex-col   ">
                <div class="mb-2 self-center font-bold">Upload an image of your ID (BACK) 25MB</div>
                 <div class="self-center">
                   <UploadFile id="back_id" ref="backImage" :componentId="'back_id'" required @changed="update"/>
                 </div>
              </div>
              </div>
              <div class="flex flex-row md:flex-row sm:flex-col xs:flex-col xs:space-x-0">
              <div class=" p-4 my-2 w-full flex flex-col" @click="this.openCamera">
              <div class="mb-2 self-center font-bold   ">Take a Selfie With your ID</div>
                <div class="self-center flex flex-col items-center">
                  <div class="border border-dashed border-gray-300 hover:border-blue-400 h-32 w-32 bg-gray-50 flex rounded flex-col place-content-center">
                      <div class="m-auto max-h-1 -mb-1" v-if="!selfie">
                        <CameraOutlined :style="{fontSize: '30px'}" />
                      </div>
                      <span v-text="'Take a Selfie'" class="m-auto font-bold text-gray-500" v-if="!selfie"/>
                      <img :src="selfie" v-else/>
                    </div>
                     <div v-if="this.selfie_empty" class="text-red-500 pt-2 ">
                    Please take a selfie of your self
                  </div>
                  <!-- <UploadFile id="front_id"   ref="frontImage"  :componentId="'front_id'" required @changed="update"/> -->
                </div>
               
              </div>
            <div class=" p-4 my-2 w-full flex flex-col" @click="show_signature_pad= true">
              <div class="mb-2 self-center font-bold  ">Add Signature</div>
                <div class="self-center flex flex-col items-center">
                  <div class="border border-dashed border-gray-300 hover:border-blue-400 h-32 w-32 bg-gray-50 flex rounded flex-col place-content-center">
                      <div class="m-auto max-h-1 -mb-1" v-if="!signature">
                        <img src="@/assets/images/papsi_signature_icon.svg" :style="{height: '30px',width: '30px'}" class="h-5"/>
                      </div>
                      <span v-text="'Signature'" class="m-auto  text-gray-500" v-if="!signature"/>
                      <img :src="signature" v-else/>
                    </div>
                     <div v-if="this.signature_empty" class="text-red-500 pt-2">
                        Please insert signature here
                      </div>
                  <!-- <UploadFile id="front_id"   ref="frontImage"  :componentId="'front_id'" required @changed="update"/> -->
                </div>
              </div>
              </div>
              <SignaturePad id="signature" class="self-center" @changed="update" v-if="show_signature_pad"/>
               <!-- <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary max-w-lg self-center" v-if="show_selfie_modal">
                  <TakeAPhoto id="selfie" ref="selfie" required @changed="update" v-if="show_selfie_modal"/>
              </div> -->
            </div>
          </div>
            <div class="flex text-lg font-bold mb-1  ">
              INTERVIEW PSO, DATE & TIME
            </div>
              <div class=" flex flex-row space-x-1 xs:space-y-3 md:flex-row sm:flex-col xs:flex-col xs:space-x-0">
                <Dropdown required :id="'branch_code'" class="w-full" placeholder="Select PSO" componentId="branch_code" ref="Branch" :items="branches" @changed="update"/>
                <DatePicker :dates="enabled_dates" required customCalendar placeholder="Preferred Interview Date" class="max-w-xs" componentId="interview_date" ref="IntDate" interview @changed="update"/>
                <Dropdown required :id="'interview_time'" class="w-full max-w-xs" placeholder="Preferred Time" componentId="interview_time" ref="interview" :items="interview_time_list" @changed="update"/>
              </div>
          <div class="flex  text-lg font-bold mb-1">
               <span v-text="'REFERRAL'" class=" "/>
            </div>
            <Container class="h-fit" flex_direction='flex-row space-x-1  pb-5 md:flex-row sm:flex-col xs:flex-col xs:space-x-0' back_ground_color="transparent">
                <TextField :max_length="6 " uppercase nonumbers :id="'ref_account'" type="text" :placeholder="'Account/Serial No.'" @changed="update"/>
                <TextField uppercase nonumbers :id="'ref_name'" type="text" :placeholder="'Name'" @changed="update"/>
              </Container>
               <div class="flex  text-lg font-bold mb-1  ">
                  <span v-text="'AGREEMENT'"/>
                </div>
              <div class="border bg-white border-gray-300 rounded shadow flex flex-col">
                <div class="w-full items-center flex flex-wrap pt-2">
                  <CheckBox id="sc_agree" class="text-left pt-2 pl-3 xs:pl-0 flex flex-row flex-wrap" @changed="updateSc" :checkbox_width="25"/>
                      <span v-text="lang('allow.checkbox.text.first')" class="font-thin text-lg"/>
                      <div class="flex flex-row items-center">
                        <div class="max-w-xs ">
                          <TextField :id="'sc'" ref="sc" type="number" :required="sc_agree == 1" class=" p-2" :placeholder="'AMOUNT'" @changed="update"/>
                        </div>
                        <span v-text="lang('allow.checkbox.text.second')" class="font-thin text-lg w-full"/>
                      </div>
                      <span v-text="lang('allow.checkbox.text.third')" class="font-thin text-lg w-full pl-10 -mt-3"/>
                      
                    <!-- <CheckBox id="allow" class="text-left pt-2 pl-3 xs:pl-0" :checkbox_width="25" @changed="sameAddress"> -->
                    
                </div>
                <div>
                  <CheckBox id="wcp_agree" class="text-left pt-2 pl-3 xs:pl-0" @changed="updateWcp" :checkbox_width="25">
                    <span v-text="lang('qualified.checkbox.text')"/>
                  </CheckBox>
                </div>
                <div class="pb-2">
                  <CheckBox required id="cisa_agree" class="text-left pt-2 pl-3 xs:pl-0" @changed="updateCisa" :checkbox_width="25">
                    <span v-text="lang('authorized.checkbox.text')"/>
                  </CheckBox>
                </div>
              </div>
          </div>
      </Card>
  </Container>
</template>
<script>
import { defineComponent } from 'vue'
import Container from '@/components/sections/ContainerView.vue';
import Card from '@/components/controls/CardView.vue';
import TextField from '@/components/controls/TextBoxField.vue';
import CheckBox from '@/components/controls/CheckBox.vue';
// import UploadFile from '@/components/controls/UploadControl.vue';
import UploadFile from '@/components/controls/AntUpload.vue';
import Dropdown from '@/components/controls/DropDownControl.vue';
import DatePicker from '@/components/controls/DatePicker.vue';
// import TakeAPhoto from '@/components/controls/TakeAPhoto.vue';
import { CameraOutlined } from '@ant-design/icons-vue';
// import Modal from '@/components/controls/ModalControl.vue';
import SignaturePad from '@/components/controls/SignaturePad.vue';
import moment from 'moment';
import mixin from "@/mixin";

export default defineComponent({
  components: {
    Container,
    Card,
    TextField,
    UploadFile,
    Dropdown,
    DatePicker,
    // TakeAPhoto,
    // Modal,
    CameraOutlined,
    SignaturePad,
    CheckBox
  },
  props:{
    id:{
      type:String,
      required:true,
    }
  },
  data(){
    return {
      branches:[],
      // branches:['PAFCPIC SATELLITE OFFICE - TARLAC','PAFCPIC SATELLITE OFFICE - ISABELA','PAFCPIC SATELLITE OFFICE - MAGSAYSAY','PAFCPIC SATELLITE OFFICE - BICOL','PAFCPIC SATELLITE OFFICE - BUTUAN','PAFCPIC SATELLITE OFFICE - ILOILO','PAFCPIC SATELLITE OFFICE - CEBU','PAFCPIC SATELLITE OFFICE - SAMAR','PAFCPIC SATELLITE OFFICE - PAGADIAN','PAFCPIC SATELLITE OFFICE - CDO','PAFCPIC SATELLITE OFFICE - DABAO','PAFCPIC SATELLITE OFFICE - COTABATO','PAFCPIC SATELLITE OFFICE - ILOCOS','PAFCPIC SATELLITE OFFICE - GHQ','PAFCPIC SATELLITE OFFICE - ZAMBOANGA','PAFCPIC SATELLITE OFFICE - TANAY','PAFCPIC SATELLITE OFFICE - LUCENA','PAFCPIC SATELLITE OFFICE - GENSAN','PAFCPIC SATELLITE OFFICE - JAMINDAN','PAFCPIC SATELLITE OFFICE - CAPAS','PAFCPIC SATELLITE OFFICE - NEGROS','PAFCPIC SATELLITE OFFICE - LEGAZPI','PAFCPIC SATELLITE OFFICE - PALAWAN'],
      front_id:'',
      back_id:'',
      ref_account:'',
      ref_name:'',
      signature:'',
      branch_code:'',
      branch_name:'',
      interview_time:'',
      interview_time_list:[],
      from:'',
      to:'',
      sc:null,
      sc_agree:null,
      wcp_agree:null,
      cisa_agree:null,
      interview_date:'',
      selfie_empty:false,
      signature_empty:false,
      selfie:null,
      schedule:'',
      enabled_dates:[],
      spouse_list:['spouse'],
      show_selfie_modal:false,
      show_signature_pad:false,
    }
  },
 mixins: [
    mixin,
  ],
  methods:{
    pushBranchRef(){
      let that = this;
      let store = that.$store;
      let state = store.state;
      let references = state.references;
      this.branches = [];
      // console.log(references.branches[0].label);
         for(let i = 0; i < references.branches.length; i++){
          let branch_name = references.branches[i].label.replace('PAFCPIC SATELLITE  OFFICE - ','');
          branch_name = branch_name.replace('PAFCPIC SATELLITE OFFICE - ','');
          this.branches.push(branch_name);
       }
      
    },
    updateSc(e){
      let that = this;
      let store = that.$store;
      let state = store.state; 

      state.membership_details.sc_agree = e == true? 1: 0;
      that.sc_agree = e == true? 1: 0;
    },

    updateWcp(e){
      let that = this;
      let store = that.$store;
      let state = store.state; 

      state.membership_details.wcp_agree = e == true? 1: 0;
      
    },

    updateCisa(e){
      let that = this;
      let store = that.$store;
      let state = store.state; 

      state.membership_details.cisa_agree = e == true? 1: 0;
      
    },
    update(e) {
          let key = e.key;
          let that = this;
          let store = that.$store;
          let state = store.state;

          that.error_message = false;
          that[key] = e.value;
          // console.log(e);
          if(key=='branch_code') {
            // console.log(e.value);
            that.branch_name = e.value;
            that.branch_code = that.getBranchCode(e.value);
            that.$refs.IntDate.clearDate();
            that.$refs.interview.clearValue();
            // console.log(that.interview_date);
            state.membership_details.interview_date = null;
            state.membership_details.schedule = null;
          }
          if(key == 'interview_date') {
            that.pushTimeList(); 
            that.$refs.interview.clearValue();
          }
          if(key == 'interview_time') {
            that.getSchedKey(e.value);
          }
          // console.log(e.value);
          that.show_selfie_modal =  false;
          that.show_signature_pad = false;
          state.membership_details.snap = that.selfie;
          state.membership_details.sc = that.sc;
          state.membership_details.interview_date = that.interview_date;
          state.membership_details.pso = that.branch_code;
          state.membership_details.signature = that.signature;
          state.membership_details.front = that.front_id;
          state.membership_details.back = that.back_id;
          state.membership_details.ref_account = that.ref_account;
          state.membership_details.ref_name = that.ref_name;
          state.membership_details.confirmed = moment(Date.now()).format('MM/DD/YYYY');
          // console.log(state.membership_details);
        },
        addSpouse() {
            let spouse_count = this.spouse_list.length;
            if(spouse_count < 4){
               this.spouse_list.push('spouse'+spouse_count);
            }
        },
         pushTimeList(){
            let that = this;
            let store = that.$store;
            let state = store.state;
            let references = state.references.branches;
            let schedule_list_array=[];
            that.interview_time_list = [];
            schedule_list_array =references.find(d => d.code == that.branch_code);
            for(let i = 0; i < schedule_list_array.schedule.length; i++){
                if(that.interview_date.toString() === schedule_list_array.schedule[i].date.toString()) {
                    that.interview_time_list.push('From ' + schedule_list_array.schedule[i].from + ' To ' + schedule_list_array.schedule[i].to);
                    that.from= schedule_list_array.schedule[i].from;
                    that.to=schedule_list_array.schedule[i].to;
                }
            }

        },
        openCamera(){
          this.$emit('openCamera');
        },
        getSchedKey(interview_time){
          let that = this;
          let store = that.$store;
          let state = store.state;
          let references = state.references.branches;
          let schedule_list_array=[];
          schedule_list_array =references.find(d => d.code == that.branch_code);

          let from = interview_time.substring(5, 13);
          let to = interview_time.substring(25, 17);
          // console.log(from);
          // console.log(to);
          
          for(let i = 0; i < schedule_list_array.schedule.length; i++){
                if(that.interview_date.toString() == schedule_list_array.schedule[i].date.toString() && schedule_list_array.schedule[i].from == from && schedule_list_array.schedule[i].to == to) {
                    // console.log(that.interview_time);
                    // console.log(schedule_list_array.schedule[i].from.toString());
                    // console.log(schedule_list_array.schedule[i].to.toString());

                    // console.log(that.interview_date.toString());
                    // console.log(that.from.toString());
                    // console.log(that.to.toString());
                    state.membership_details.schedule = schedule_list_array.schedule[i].key;
                    // console.log(state.membership_details.schedule);
                }
                
            }
            
        },
        removeSpouse(e){
          this.spouse_list.splice(e,1);
          // console.log(e);
          // console.log(this.spouse_list);
        },
        getBranchCode(e){
          let that = this;
          let store = that.$store;
          let state = store.state;
          let references = state.references;
          for(let index = 0; index < references.branches.length ; index++) {
            let branch_name = references.branches[index].label.replace('PAFCPIC SATELLITE  OFFICE - ','');
                branch_name = branch_name.replace('PAFCPIC SATELLITE OFFICE - ','');
            if(branch_name  == e){
              state.membership_details.pso_text=references.branches[index].label;
              that.schedule = references.branches[index].schedule;
              that.changeCalendar();
              // console.log(state.membership_details.pso_text);
              // console.log(references.branches[index].code);
              return references.branches[index].code;
            }
          }
        },
        changeCalendar(){
          let that = this;
          that.enabled_dates = [];
          for(let i = 0; i<that.schedule.length ; i++){
            that.enabled_dates.push(that.schedule[i].date);
          }
        },
        checkEmpty(){
          if(this.selfie == null || this.selfie ==''){
              this.selfie_empty =true;
          }else {
            this.selfie_empty = false;
          }
          if(this.signature == null || this.signature == ''){
            this.signature_empty = true;
          }else{
            this.signature_empty = false;
          }
        },
        checkFields() {
        let ref = this.$refs;
        let store = this.$store;
        let state = store.state.membership_details;
        ref.Branch.checkEmpty();
        ref.IntDate.checkEmpty();
        ref.frontImage.checkEmpty();
        ref.backImage.checkEmpty();
        ref.interview.checkEmpty();

        if(state.sc_agree == 1) {
          ref.sc.checkEmpty();
        }
        // ref.signature.checkEmpty();
      },
  }
})
</script>
<style scoped>
.max_height {
  height: calc(70% - 420px) !important;
}
.static_modal {
  position: static !important;
}
</style>