<template>
<div v-show="show" class="w-full h-screen bg-translucent left-0 top-0 z-50 transition delay-100 duration-500 ease-in-out flex flex-col p-2 absolute" v-bind:class="[{'-translate-x-0' : this.popUp ,'-translate-y-full':!this.popUp}]">
     <div class="p-3 text-md bg-translucent font-bold flex flex-row relative justify-end">
        <div class="text-2xl cursor-pointer bg-white rounded-full pl-2 pr-2 absolute -mr-2" @click="closed">
            X
        </div>
    </div>
    <div class="w-full h-full p-6" @click="positiveClick">
        <div class="w-full h-full">
            <iframe src="https://media.pafcpic.net/resource/proposed_amendments.pdf#toolbar=0" frameborder="1" class="w-full h-full" id="ctIframe"  runat="server">
            </iframe>
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            show: true,
            popUp: false,
        }
    }, 
    props:{
        display: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods:{
        closed(){
            this.show = false;
            this.$emit('closedAmendments');
        }
    },
    watch: {
        display(n) {
            this.show = n;
            setTimeout(() => {
                this.popUp = n;
            }, 10);
        }
    },
})
</script>
<style scoped>
.bg-translucent {
    background-color: rgba(0, 0, 0, 0.75);
}
.modal-max-h {
    max-height: calc(100vh - 200px);
}
</style>