import services from "../service";

export default {
    postApplication(details: any) {
        return services.post('/member/join', details);
    },
    confirmed(details: any) {
        return services.post('/member/confirm/application', details);
    },
    updateSelectedBranch(details: any) {
        return services.post('/member/update/branchSelected', details);
    },
    checkCredentials(details: any) {
        return services.post('/member/check/credentials', details);
    },
    requestOtp(payload: any) {
        return services.post('/member/requesOtp', payload);
    },
    resendOtp(payload: any) {
        return services.post('/member/resendOtp', payload);
    },
    getProvince() {
        return services.post('/member/province');
    },
    getCity() {
        return services.post('/member/city');
    },
    getBranch() {
        return services.post('/member/branch');
    },
    getMemberInfoByAccountNumber(account_number: string) {
        return services.get('/member/find-account/'+account_number);
    },
    getMemberInfoBySerialNumber(serial_number: string) {
        return services.get('/member/find-serial/'+serial_number);
    },
    getMemberInfoByHash(hash: string) {
        return services.get('/member/'+hash);
    },
    getReference(){
        return services.get('/member/application/refs');
    },
    validateApplication(payload){
        return services.post('/member/application/validate',payload);
    }
}