<template>
<div class="h-full w-full gradient">
<Modal v-show="show_message_modal" 
      v-bind:display="show_message_modal"
      :error="this.error"
      :success="this.success"
      :note="this.warning"
      z_value="z-50"
      @positiveClick="messageClosed"
      :positiveButton="'OK'"
      :default_width="'max-w-xl'"
      id='vote_modal'>
      <div class="w-full p-5 flex flex-col text-xl font-bold">
          {{ this.modal_message}}
      </div>
</Modal>
<div :class="[{'hidden':!loading}]">
    <Loading :loading="!loading"/>
</div>
    <div v-if="this.$store.state.user.selected_candidatesBodOf != null" class="h-fit w-full gradient xs:p-2 flex justify-center overflow-y-auto p-5" :class="[{'hidden':loading || show_rate}]">
        <div class="md:w-1/2 xs:w-full bg-white h-fit flex flex-col mb-10">
            <div class="w-full flex mt-10">
                   <img src="https://media.pafcpic.net/images/logo/pafcpic.png" class="m-auto w-32 h-32 z-40" />
           </div>
           <div class="p-5 text-center font-mono font-bold text-2xl" style="font-family: Courier New, Courier, monospace">
             <span>
                PHILIPPINE ARMY FINANCE CENTER PRODUCERS INTEGRATED COOPERATIVE
             </span>
           </div>
            <div class="p-4">
                <div class="border border-black w-full"/>
             </div>
             <div class=" p-4 text-lg text-black" style="font-family: Courier New, Courier, monospace">
                <div>
                    <span v-text="'BALLOT NO.:'"/>
                    <span v-text="this.$store.state.user.ballot_details.ballot"/>
                </div>
                <div>
                    <span v-text="'NAME:'"/>
                    <span v-text="this.$store.state.user.ballot_details.user.name"/>
                </div>
                <div>
                    <span v-text="'ACCOUNT NO.:'"/>
                    <span v-text="this.$store.state.user.ballot_details.user.account_no"/>
                </div>
                <div>
                    <span v-text="'SERIAL NO.:'"/>
                    <span v-text="this.$store.state.user.ballot_details.user.serial_no"/>
                </div>
                <div>
                    <span v-text="'NUMBER OF VOTES:'"/>
                    <span v-text="this.$store.state.user.ballot_details.count"/>
                </div>
             </div>
             <div class="p-4">
                <div class="border border-black w-full"/>
             </div>
           <div class=" p-4">
                <div class="text-2xl font-bold" style="font-family: Courier New, Courier, monospace">I. BOARD OF DIRECTORS</div>
                <div class="p-2">
                    <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace">OFFICER CATEGORY</div>
                    <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesBodOf" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                    </div>
                    <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace">ENLISTED PERSONNEL CATEGORY</div>
                    <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesBodEp" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                    </div>
                    <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace">CIVILIAN CATEGORY</div>
                    <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesBodCv" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                    </div>
                </div>
                <div class="text-2xl font-bold" style="font-family: Courier New, Courier, monospace">II. ELECTION COMMITTEE</div>
                <div class="p-2">
                        <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace"> OFFICER CATEGORY</div>
                        <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesElecomOf" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                        </div>
                        <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace"> ENLISTED PERSONNEL CATEGORY</div>
                        <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesElecomEp" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                        </div>
                         <div class="font-bold text-xl ml-3" style="font-family: Courier New, Courier, monospace"> CIVILIAN CATEGORY</div>
                        <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesElecomCv" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                        </div>
                </div>
                <div class="text-2xl font-bold" style="font-family: Courier New, Courier, monospace">III. AUDIT & SUPERVISORY COMMITTEE</div>
                <div class="p-2">
                        <div class="space-x-2 text-lg ml-6" style="font-family: Courier New, Courier, monospace" v-for="(items,index) in this.$store.state.user.selected_candidatesAudit" :key="index">
                                <span v-text="index+1+'.'" />
                                <span v-text="items.name" />
                        </div>
                </div>
                <div class="p-4">
                    <div class="border border-black w-full"/>
                </div>

                <div class="p-2">
                    <div class="flex flex-col text-black" style="font-family: Courier New, Courier, monospace">
                        <div>
                            <span v-text="'Date Registered:'"/>
                            <span v-text="this.$store.state.user.ballot_details.dates.registered"/>
                        </div>
                        <div>
                            <span v-text="'Date Started:'"/>
                            <span v-text="this.$store.state.user.ballot_details.dates.started"/>
                        </div>
                        <div>
                            <span v-text="'Date Casted:'"/>
                            <span v-text="this.$store.state.user.ballot_details.dates.casted"/>
                        </div>
                    </div>
                </div>
           </div>
            <div class="w-full bg-white p-5">
                <div @click="click"
                class="border p-3 text-white bg-red-500 hover:text-white cursor-pointer text-lg font-bold rounded text-center shadow-lg ">
                    <span v-text="'PROCEED'" />
                </div>
            </div>
        </div>
    </div>
    <div class="w-full h-full flex relative" v-if="show_rate">
        <div class="border flex  border-gray-400 m-auto bg-white rounded-md xs:mt-2 flex-row relative p-5 space-y-2">
            <div class="flex flex-col relative">
                <span v-text="lang('how.experience')" class="italic text-lg"/>
                <Rating @score="Savescore" />
                  <span v-text="message" class="text-red-500"/>
                <div class="flex flex-row space-x-2 pt-2">
                    
                <div @click="submitVote" class="border w-full border-theme-primary p-3 text-theme-primary hover:bg-theme-primary hover:text-white cursor-pointer text-lg font-bold rounded text-center shadow-lg ">
                    <span v-text="'OK'" />
                </div>
                <div @click="back" class="border w-full border-theme-primary p-3 text-theme-primary hover:bg-theme-primary hover:text-white cursor-pointer text-lg font-bold rounded text-center shadow-lg ">
                    <span v-text="'SKIP'" />
                </div>
                </div>
                <img src="@/assets/icons/hi.png" class="max-w-sm fixed self-center ml-32 mt-20 sm:hidden md:hidden"/>
            </div>
             <div class="place-content-center xs:hidden">
                <img src="@/assets/icons/hi.png" class="max-w-sm absolute self-center"/>
             </div>
        </div>
    </div>
 </div>   
</template>

<script>
import { defineComponent } from "vue";
import mixin from "@/mixin";
import User from "../../services/User";
import Rating from "@/components/controls/Rating.vue";
import Loading from "@/components/controls/LoadingControl.vue";
import Modal from "@/components/controls/ModalControl.vue";
export default defineComponent({
    mounted() {
        this.checkStarted();
        this.checkExistingCookie();
    },
    data(){
        return {
            loading: false,
            score:null,
            message:null,
            show_rate:false,
            modal_message:null,
            show_message_modal:false,
            error:false,
            success:false,
            warning:false,
        }
    },
    mixins: [
        mixin,
    ],
    components:{
        Rating,
        Loading,
        Modal
    },
    methods:{
    messageClosed(){
        this.error = false;
        this.success = false;
        this.warning = false;
        this.show_message_modal=false;
    },
    checkExistingCookie() {
        let that = this;
       const cookie = that.$cookies;
       const store = that.$store;
      //  let log = store.state.logged;
       let state = store.state;
        state.hash = cookie.get('user').hash;
        that.loading = true;
        User.getuserInfo(cookie.get('user')).then(function(response){
          let user_data = response.data.data;
              //election
              
              state.election_config.date_end = user_data.election.vote_end;
              state.election_config.date_start = user_data.election.vote_start;
              state.election_config.server_time = response.timestamp;
              state.election_config.election_registered = user_data.election.registered;
              state.election_config.can_vote = user_data.election.can_vote;
              state.election_config.voted = user_data.election.voted;
              state.election_config.voting_ended = user_data.election.voting_ended;
              state.election_config.registration_start=user_data.election.reg_start;
              state.election_config.registration_end= user_data.election.reg_end;
              state.election_config.registration_started= user_data.election.reg_started;
              
              //credential
              state.credentials.name = user_data.account.username;
              state.credentials.first_name=user_data.info.first_name;
              state.credentials.last_name=user_data.info.last_name;
              state.credentials.middle_name=user_data.info.middle_name;
              state.credentials.gender=user_data.info.gender;
              state.credentials.regular=user_data.info.regular;
              state.credentials.account_no = user_data.info.account_no;
              state.credentials.serial_no = user_data.info.serial_no;
              state.credentials.tin= user_data.info.tin;
              state.credentials.email = user_data.account.email;
              state.credentials.mobile = user_data.account.mobile;
              state.credentials.username = user_data.account.username;
              state.credentials.qr =user_data.qr;
              state.credentials.uuid = user_data.uuid;
              state.credentials.hash = cookie.get('user').hash;
              state.credentials.key = user_data.key;
              state.credentials.membership_date = user_data.info.membership_date;
              state.credentials.registered_date = user_data.account.registered;
              state.credentials.status = user_data.info.status;
              state.credentials.membership_type = user_data.info.membership_type;
              state.credentials.deactivated = user_data.account.deactivated;
              that.loading = false;
                if(user_data.account.express){
                that.$router.push('/user/settings');
              }
              if(user_data.account.deactivated){
                that.$router.push('/user/settings');
              }
                // that.$router.push('/dashboard');
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
             if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
        });
        },
        click() {
            const that = this;
            const store = that.$store;
            const state = store.state;

            that.success =true;
            that.show_message_modal =true;
            that.modal_message = "Thank you for participating in this year's Annual Regular General Assembly Meeting and Election!";
            // state.user.rated = true;
            state.user.selected_candidatesBod = null;
            state.election_config.finished = true;
            that.show_rate= true;
        },
        Savescore(e){
            this.score = e; 
        },
        back(){
         this.$router.push('/Dashboard');
        },
        checkStarted() {
            const that = this;
            const store = that.$store;
            const state = store.state;

            if(state.election_config.finished) {
                that.$router.push('/Dashboard');
            }
        },
        submitVote(){
            let that = this;
            const cookie = that.$cookies;
            let score_payload = {
                type:'1',
                score:that.score,
            };
            
            if(that.score == null){
                that.message = "Please select a star to rate your experience";
            }else{
                that.loading = true;
                User.rating(cookie.get('user').uuid,score_payload).then(function() {
                that.loading = true;
                that.$router.push('/Dashboard');
                }).catch(function(){
                    that.loading = false;
                });
            }
            
        }
    }

})
</script>
<style>
.gradient{
   background-image: linear-gradient(to top,#9CA3AF, #eaebed);
}
</style>