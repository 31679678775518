<template>
      <div class="w-full">

    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({

})
</script>
