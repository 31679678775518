import services from "../service";

export default {
    getCity(province_id: string) {
        return services.getAddress('https://psgc.gitlab.io/api/provinces/'+province_id+'/cities-municipalities');
    },
    getProvince() {
        return services.getAddress('https://psgc.gitlab.io/api/provinces');
    },
    getBarangay(city_code: string) {
        return services.getAddress('https://psgc.gitlab.io/api/cities-municipalities/'+city_code+'/barangays/');
    },
    getNcrBarangays(){
        return services.getAddress('https://psgc.gitlab.io/api/regions/130000000/barangays');
    },
    getNcrCities(){
        return services.getAddress('https://psgc.gitlab.io/api/regions/130000000/cities-municipalities');
    }
}