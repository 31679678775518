   <template>
    <div class="bg-white rounded shadow-lg w-full relative" :class="[max_width,background,{'gradient':gradient},max_height]">
        <div class="w-full h-[100px] absolute flex content-center mt-24" v-if="Logo">
             <img src="https://media.pafcpic.net/images/logo/pafcpic-nav.png" class="w-full max-w-md" />
        </div>
        <div v-if="title" class="border-b border-gray-200 p-3 font-bold text-xl " :class="[text_color]">
            <span v-text="title_morph()"/>
        </div>
        <div class="w-full h-full flex place-content-center" :class="direction">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
 props:{
    gradient:{
        type:Boolean
    },
    max_height:{
        type: String,
        required: false,
    },
    max_width: {
        type: String,
        required: false,
    },
    text_color:{
        type: String,
    },
    title:{
        type:String,
        required:false,
    },
    Logo: {
        type:Boolean,
        required:false,
    },
    background:{
        type:String,
        required:false,
    },
    direction:{
        type:String,
        required:false,
    }
 },
 methods:{
    title_morph() {
        return this.title.toUpperCase();
    }
 }
})
</script>
<style scoped>
.gradient {
    background-image: linear-gradient(to right,#01A500, #FEE765);
}
</style>
