<template>
    <div class="w-full flex flex-col  content-center">
        <Loading v-show="loading" :loading="loading"/>
        <Modal v-show="this.show_message_modal" 
        v-bind:display="this.show_message_modal"
        :error="this.error"
        :success="this.success"
        :note="this.warning"
        z_value="z-50"
        :default_width="'max-w-xl'"
        id='vote_modal'>
        <div class="w-full p-5 flex flex-col text-2xl font-bold ">
        {{ this.modal_message}}
        </div>
        <div class="flex flex-row space-x-1 p-3">
            <Button :button_text="this.error||this.success?'OK':'YES'" @clicked="submit"/>
            <Button :button_text="'NO'" v-if="this.warning" @clicked="closed_modal" :theme="'bg-red-700'"/>
        </div>
        </Modal>
        <Modal v-show="show_otp_modal" 
        v-bind:display="show_otp_modal"
        @positiveClick="submitOtp"
        :positiveButton="'OK'"
        :z_value="'z-40'"
        :default_width="'max-w-xl'"
        id='otp_modal'>
        <div class="pb-4 text-sm italic text-left p-2">
                Please enter the one time pin we have sent to your email or mobile number so that we can verify your identity.
            </div>
            <div class="pb-2 font-bold uppercase text-left p-2">
            Enter OTP
            </div>
            <div class="pb-2 font-bold uppercase text-left p-2">
                <TextBoxField @changed="otpUpdate" id="otp" type="text"/>
            </div>
            <div class="pb-2 font-bold uppercase text-left p-2" v-show="show_resend_otp">
            <LinkText emit_triggered='resendOtp' @resendOtp="resendOtp" class="text-theme-primary font-bold" text="Resend"/>
            </div>
        </Modal>
    <NavBarView class="h-14 fixed" special :user_name="this.username" @showMenu="showMenu"/>
        <div class="w-full  flex xs:flex-col  m-auto mt-24 max-w-7xl">
            <div class=" flex w-full md:max-w-sm flex-col xs:flex-col text-lg xs:mt-12  xs:mr-2 xs:ml-2 xs:mb-2 space-y-2">
                  <div @click="showUpdateUsernameView" 
                    :class="[{'border rounded bg-[#016B00] text-[#ffff] border-theme-primary   font-bold': showUpdateUsername},{'hidden':express || deactivated}]"
                    class="w-full bg-white border shadow-lg   text-black rounded p-5 cursor-pointer hover:text-white hover:bg-theme-primary hover:font-bold">
                    <span v-text="'ACCOUNT INFORMATION'" />
                </div>
                <div @click="showUpdateEmailView" class="w-full border bg-white shadow-lg  text-black rounded p-5 cursor-pointer hover:text-white hover:bg-theme-primary hover:font-bold"
                    :class="[{'border bg-[#016B00] text-[#ffff] rounded border-theme-primary font-bold': showUpdateEmail},{'hidden': deactivated}]">
                    <span v-text="'CONTACT INFORMATION'" />
                </div>
                <div @click="showUpdatePasswordView" class="w-full border bg-white shadow-lg  text-black rounded p-5 cursor-pointer hover:text-white hover:bg-theme-primary hover:font-bold"
                    :class="[{'border bg-[#016B00] text-[#ffff] rounded border-theme-primary font-bold': showPasswordUpdateView},{'hidden':express || deactivated}]">
                    <span v-text="'MEMBER INFORMATION'" />
                </div>
                <div @click="showSecurity" class="w-full border bg-white shadow-lg  text-black rounded p-5 cursor-pointer hover:text-white hover:bg-theme-primary hover:font-bold"
                    :class="[{'border bg-[#016B00] text-[#ffff] rounded border-theme-primary font-bold':showSecurityView},{'hidden':express}]">
                    <span v-text="'SECURITY'" />
                </div>
            </div>
        <div class=" w-full rounded flex flex-row xs:pb-16  pl-2">
            <div class="w-full h-full">
                <div v-show="showUpdateUsername" class="w-full h-full" >
                <div class="flex flex-col w-full space-y-2  xs:p-0">
                    <div class="border flex-col bg-white rounded shadow-md flex justify-center h-full p-8 ">
                        <div class="text-2xl font-bold -mt-7 mb-5 -ml-4 border-b p-2">
                            <span v-text="'EDIT INFORMATION'" class="w-full" />
                        </div>
                        <div class="w-full h-full flex flex-row ">
                            <div class="text-lg w-full flex flex-col space-y-2" v-if="this.currentUsername" >
                                <div>
                                    <span v-text="'USERNAME'" class="w-full" />
                                </div>
                                <div class="w-full flex xs:flex-col">
                                    <TextBoxField @changed="changed" :initial="this.currentUsername" id="username" class="w-full"/> 
                                    <Button rounded @clicked="updateUsernameTrigger" button_text="UPDATE" :loading="this.updatingUsername"  class="xs:self-end xs:mr-0 max-w-xxs ml-2 mr-2 mt-2"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border rounded bg-white shadow-md flex flex-col justify-center h-full p-8">
                        <div class="text-2xl font-bold -mt-7 mb-5 -ml-4 border-b p-2">
                            <span v-text="'EDIT PASSWORD'" class="w-full" />
                        </div>
                        <div class="w-full h-full flex flex-col">
                            <form class="text-lg w-full flex flex-col space-y-2" >
                                <div>
                                    <span v-text="'OLD PASSWORD'" class="w-full" />
                                </div>
                                <div class="w-full flex">
                                  <TextBoxField id="old_password" peekable @changed="changed" type="Password"/>
                                </div>
                                <div>
                                    <span v-text="'NEW PASSWORD'" class="w-full" />
                                </div>
                                <div class="w-full flex">
                                    <TextBoxField id="new_password" peekable @changed="changed" type="Password"/>
                                </div>
                                 <div>
                                    <span v-text="'CONFIRM PASSWORD'" class="w-full" />
                                </div>
                                <div class="w-full flex">
                                    <TextBoxField id="con_password" @changed="changed" type="Password"/> 
                                </div>
                                </form>
                                <div class="flex  float-right pt-2 w-full self-end">
                                    <Button rounded @clicked="updatePasswordTrigger" class="w-full max-w-xxs" button_text="UPDATE" :loading="this.updatingPassword"  />
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="showUpdateEmail" class="gap-x-5 gap-y-5 h-full">
                <div class="border flex-col bg-white rounded shadow-md flex justify-center p-8">
                    <div class="text-2xl font-bold -mt-7 mb-5 -ml-4 border-b p-2">
                        <span v-text="'EDIT CONTACT INFORMATION'" class="w-full" />
                    </div>
                    <div class="w-full h-full flex flex-row" >
                        <div class="text-lg w-full flex flex-col"  v-if="!express">
                            <div v-if="express">
                                <span v-text="'PLEASE UPDATE AND VERIFY YOUR EMAIL OR MOBILE NUMBER'" class="w-full text-red-500 italic" />
                            </div>
                            <div>
                                <span v-text="'EMAIL ADDRESS'" class="w-full" />
                            </div>
                            <div class="w-full flex xs:flex-col" v-if="currentEmail">
                                <TextBoxField @changed="changed" :initial="this.currentEmail" type="email" id="email"/> 
                                <Button rounded @clicked="updateEmailTrigger" :disabled="!enableEmail" button_text="UPDATE" :loading="this.updatingEmail" class="xs:self-end xs:mr-0 max-w-xxs ml-2 mr-2 mt-2"/>
                            </div>
                            <div>
                                <span v-text="'MOBILE NUMBER'" class="w-full" />
                            </div>
                            <div class="w-full flex xs:flex-col" v-if="currentMobile">
                                <TextBoxField nospecialchar @changed="changed" :initial="this.currentMobile" type="mobile" id="mobile_number" :max_length="9"/>
                                <Button rounded @clicked="updateMobileTrigger" :disabled="!enableMobile" button_text="UPDATE" :loading="this.updatingMobile" class="xs:self-end xs:mr-0 max-w-xxs ml-2 mr-2 mt-2"/>
                            </div>
                        </div>
                        <div class="text-lg w-full flex flex-col"  v-if="express">
                            <div>
                                <span v-text="'PLEASE UPDATE AND VERIFY YOUR EMAIL OR MOBILE NUMBER'" class="w-full text-red-500 italic" />
                            </div>
                            <div>
                                <span v-text="'EMAIL ADDRESS'" class="w-full" />
                            </div>
                            <div class="w-full flex xs:flex-col" >
                                <TextBoxField @changed="changed" :initial="this.currentEmail" type="email" id="email"/> 
                                <Button rounded @clicked="updateEmailTrigger" :disabled="!enableEmail" button_text="UPDATE" :loading="this.updatingEmail" class="xs:self-end xs:mr-0 max-w-xxs ml-2 mr-2 mt-2"/>
                            </div>
                            <div>
                                <span v-text="'MOBILE NUMBER'" class="w-full" />
                            </div>
                            <div class="w-full flex xs:flex-col" >
                                <TextBoxField nospecialchar @changed="changed" :initial="this.currentMobile" type="mobile" id="mobile_number" :max_length="9"/>
                                <Button rounded @clicked="updateMobileTrigger" :disabled="!enableMobile" button_text="UPDATE" :loading="this.updatingMobile" class="xs:self-end xs:mr-0 max-w-xxs ml-2 mr-2 mt-2"/>
                            </div>
                            <div class="w-full flex xs:flex-col" v-if="express">
                                <Button rounded @clicked="verifyAccount" :disabled="verify" button_text="VERIFY" class="xs:self-end xs:mr-0 max-w-xxs mt-2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="showPasswordUpdateView">
                <div class="flex-col bg-white rounded shadow-md flex h-full p-8">
                    <div class="text-2xl font-bold -mt-7 mb-5 -ml-4 border-b p-2">
                        <span v-text="'EDIT MEMBER INFORMATION'" class="w-full" />
                    </div>
                    <div class="w-full h-full flex flex-row" >
                        <div class="text-lg w-full flex flex-col" >
                            <div>
                                <span v-text="'TIN'" class="w-full" />
                            </div>
                            <div class="w-full flex" >
                                <TextBoxField id="tin" :initial="currentTin" @changed="changed" type="text"/> 
                                <Button rounded theme="error" button_text="UPDATE" @clicked="updateTinTrigger" class="max-w-xxs ml-2 mr-2 mt-2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="showSecurityView">
                <div class="w-full" v-show="!deactivated">
                    <div class="border rounded shadow-md flex flex-row justify-center h-full p-10 ">
                        <div class="w-full h-full flex flex-col space-y-2">
                            <div class="text-lg w-full">
                                <span v-text="'Password'" />
                                <TextBoxField ref="Pass" type="password" id="deac" @changed="changed" required/>
                            </div>
                            <div class="flex ">
                                <Button theme="bg-red-500" rounded @click="deactivateAccountTrigger" :button_text="'DEACTIVATE ACCOUNT'" :loading="this.updatingPassword"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-full" v-show="deactivated">
                    <div class="border rounded shadow-md flex flex-row justify-center h-full p-10 ">
                        <div class="w-full h-full flex flex-col space-y-2">
                            <div class="text-lg w-full">
                                <span v-text="'Account No.'" />
                                <TextBoxField ref="Pass" type="text" id="reac" @changed="changed" required/>
                            </div>
                            <div class="flex ">
                                <Button theme="bg-theme-primary" rounded @click="reactivateAccountTrigger" :button_text="'REACTIVATE ACCOUNT'" :loading="this.updatingPassword"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           </div>
        </div>
    </div>
</div>
</template>
<script>
import { defineComponent } from 'vue';
import TextBoxField from '@/components/controls/TextBoxField.vue';
import Button from '@/components/controls/ButtonControl.vue';
import Loading from '@/components/controls/LoadingControl.vue';
import User from "@/services/User";
import OTP from "@/services/OTP";
import axios from 'axios'
import Modal from '@/components/controls/ModalControl.vue';
import LinkText from "@/components/controls/LinkText";
import NavBarView from '@/components/controls/NavBarView.vue';
export default defineComponent({
      components:{
        TextBoxField,
        Button,
        Loading,
        Modal,
        LinkText,
        NavBarView
      },
      data(){
        return {
            showUpdateUsername: true,
            showUpdateEmail: false,
            pin:'',
            showUpdateMobile: false,
            enableMobile: false,
            enableEmail: false,
            showAccountInfoView: true,
            showPasswordUpdateView:false,
            showSecurityView: false,
            showDeactivateAccountView:false,
            showUpdateUsernameModal:false,
            showUpdateEmailModal:false,
            showUpdateMobileModal:false,
            showUpdatePasswordModal:false,
            showDeactivateAccountModal:false,
            showUpdateTin:false,
            showSuccessModal: false,
            show_message_modal:false,
            showOtpModal: false,
            updatingUsername: false,
            updatingEmail: false,
            updatingMobile: false,
            updatingPassword:false,
            deactivatingAccount: false,
            message:'',
            otpType:'',
            otp:'',
            updateType:'',
            errors_log: [],
            currentUsername:'',
            currentEmail: '',
            verify:true,
            currentMobile: '',
            account_number:'',
            show_resend_otp:false,
            timerCount:0,
            timerEnabled:false,
            full_name:'',
            show_menu:false,
            email:'',
            number:'',
            Astatus:'',
            deac:'',
            reac:'',
            tin:'',
            Mstatus:'', 
            express:false,
            deactivated:false,
            serial:'', 
            loading:false,
            disablenum:true,
            disableemail:true,
            disableuser:true,
            username:'',
            currentTin:'',
            old_password:'',
            new_password:'',
            con_password:'',
            mobile_number:'',
            password_deact:'',
            error:false,
            success:false,
            warning:false,
            modal_message:'',
            show_otp_modal:false,
            expired:false,
    }
},
mounted(){
    const that = this;
    let store = that.$store;
    let state = store.state;
        if(state.credentials.username != null) {
        that.currentUsername = state.credentials.username;
        that.currentEmail = state.credentials.email;
        that.currentMobile = state.credentials.mobile!=null?state.credentials.mobile.replace('+63',''):'';
        that.currentTin = state.credentials.tin;
        that.deactivated = state.credentials.deactivated;
        that.express = state.credentials.express;

        if(that.deactivated){
            that.showSecurity();
        }
        if(that.express){
            that.showUpdateEmailView();
        }
        }else{
         that.testHash();
        }
       
},
methods: {
    testHash() {
        let that = this;
        const cookie = that.$cookies;
        that.loading = true;
        User.testHash(cookie.get('user').hash).then(function(){
            that.getUserInfo();
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            that.expired = true
        });
    },
    verifyAccount(){
        let that = this;
        let store = that.$store;
        let state = store.state;
        that.loading = true;
        User.verifyACcount(state.credentials.uuid).then(function(){
            that.express=false; 
            state.credentials.express=false;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = "YOUR ACCOUNT HAS BEEN VERIFIED THANK YOU!";
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            that.expired = true
        });
    },
    getUserInfo() {
        let that = this;
        const cookie = that.$cookies;
        const store = that.$store;
        //  let log = store.state.logged;
        let state = store.state;
        that.loading = true;
        User.getuserInfo(cookie.get('user')).then(function(response){
            let user_data = response.data.data;

            //CREDENTIALS
            // state.credentials.name = user_data.account.username;
            state.credentials.first_name=user_data.info.first_name;
            state.credentials.last_name=user_data.info.last_name;
            state.credentials.middle_name=user_data.info.middle_name;
            state.credentials.gender=user_data.info.gender;
            state.credentials.regular=user_data.info.regular;
            state.credentials.account_no = user_data.info.account_no;
            state.credentials.serial_no = user_data.info.serial_no;
            state.credentials.tin= user_data.info.tin;
            state.credentials.email = user_data.account.email;
            state.credentials.mobile = user_data.account.mobile;
            state.credentials.username = user_data.account.username;
            state.credentials.qr =user_data.qr;
            state.credentials.uuid = user_data.uuid;
            state.credentials.hash = cookie.get('user').hash;
            state.credentials.key = user_data.key;
            state.credentials.deactivated = user_data.account.deactivated;

            that.username = state.credentials.username;
            that.currentUsername = state.credentials.username;
            that.currentEmail = state.credentials.email;
            that.currentMobile = state.credentials.mobile!=null?state.credentials.mobile.replace('+63',''):'';
            that.currentTin = state.credentials.tin;
            that.express = user_data.account.express;
            that.deactivated = user_data.account.deactivated;
            that.loading = false;
            
            if(user_data.account.express) {
                that.showUpdateEmailView();
            }
            if(user_data.account.deactivated) {
                that.showSecurity();
            }
            if(that.currentEmail || that.currentMobile){
                that.verify = false;
            }
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
        });
    },
    deactivateAccountTrigger() {
        this.updateType = 5; 
        this.otpType = '3';
        this.modal_message = "Are you sure you want to deactivate your account?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    reactivateAccountTrigger() {
        const store = this.$store;
        let state = store.state;

        if(this.reac == state.credentials.account_no) {
             this.updateType = 7; 
             this.otpType = '4';
             this.modal_message = "Are you sure you want to reactivate your account?";
             this.warning = true;
             this.error = false;
             this.success = false;
             this.show_message_modal = true;
        }else{
            this.error = true;
            this.show_message_modal = true;
            this.modal_message = "Account number miss match";
        }
       
    },
    submit(){
        let that = this;
        let store = that.$store;
        let state = store.state;
         const cookie = that.$cookies;
         if(!that.expired)
         {
            if(that.success) {
                if(that.updateType == 5){
                    cookie.remove('user');
                    that.$router.push('/');
                }
                that.show_message_modal = false;
            }
            else if (that.error) 
            {
                that.show_message_modal = false;
            }
            else {
            if(that.updateType == 1){
                that.updateUsername();
            }
            if(that.updateType == 2){
                that.requestUpdateOtp();
            }
            if(that.updateType == 3){
                that.requestUpdateOtp();
            }
            if(that.updateType == 4){
                that.updatePassword();
            }
            if(that.updateType == 5){
                that.deactivateAccount();
            }
            if(that.updateType == 6){
                that.updateTin();
            }
            if(that.updateType == 7){
                that.requestUpdateOtp();
            }
            } 
         } else {
            if(that.expired) {
                state.credentials.hash = '';
                state.logged = false;
                that.expired = false;
                that.$cookies.remove('user');
                that.$router.push('/')
            }
         }
        
    },
    closed_modal() {
        this.show_message_modal = false;
    },
    reactivateAccount(){
        let that = this;
        const cookie = that.$cookies;
        that.loading = true;
        User.reactivate(cookie.get('user').uuid).then(function(){
            that.loading = false; 
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully reactivated your account.";
            that.getUserInfo();
            that.show_message_modal= true;
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
        });
    },
    updateUsernameTrigger() {
        this.updateType = 1; 
        this.modal_message = "Are you sure you want to update your username?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updateEmailTrigger() {
        this.otpType = '6';
        this.updateType = 2; 
        this.modal_message = "Are you sure you want to update your email?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updateMobileTrigger() {
        this.otpType = '7';
        this.updateType = 3; 
        this.modal_message = "Are you sure you want to update your mobile?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updateTinTrigger(){
        this.updateType = 6; 
        this.modal_message = "Are you sure you want to update your TIN?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    updatePasswordTrigger(){
        this.updateType = 4; 
        this.modal_message = "Are you sure you want to update your password?";
        this.warning = true;
        this.error = false;
        this.success = false;
        this.show_message_modal = true;
    },
    showUpdateUsernameView() {
        if(this.showUpdateUsername == false) {
            this.showUpdateUsername = true;
            this.showUpdateEmail = false;
            this.showUpdateMobile = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    showUpdateEmailView() {
        if(this.showUpdateEmail == false) {
            this.showUpdateEmail = true;
            this.showUpdateUsername = false;
            this.showUpdateMobile = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    updateUsername() {
        const that = this;
        const cookie = that.$cookies;
        var payload = {
            hash: cookie.get('user').hash,
            value: that.username,
        }
        that.loading = true;
        that.currentUsername = '';
        that.show_message_modal = false;
        User.editUserName(cookie.get('user').uuid,payload).then(function() {
            that.currentUsername = that.username;
            that.loading = false; 
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your username.";
            that.show_message_modal= true;
           

        }).catch(function(error){
            if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
            
        }); 
    },
    updateTin(){
        const that = this;
        const cookie = that.$cookies;
        let store = that.$store;
        let state = store.state;
        let tin = that.tin.replace('-','')
        let tin1 = tin.charAt(0) + tin.charAt(1) + tin.charAt(2);
        let tin2 = tin.charAt(3) + tin.charAt(4) + tin.charAt(5);
        let tin3 = tin.charAt(7) + tin.charAt(8) + tin.charAt(9);

        that.loading = true;
        that.currentTin = '';
        that.show_message_modal = false;

        User.updateTin(cookie.get('user').uuid,tin1,tin2,tin3).then(function() {
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your TIN.";
            that.show_message_modal= true;
            that.loading = false;
            state.credentials.tin = that.tin;
            that.currentTin = that.tin;
        }).catch(function(error){
              if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    updateEmail() {
        const that = this;
        const cookie = that.$cookies;
        var payload = {
            hash: cookie.get('user').hash,
            value: that.email,
        }
        that.loading = true;
        that.currentEmail = '';
        that.show_message_modal = false;
        User.editEmail(cookie.get('user').uuid,payload).then(function() {
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your email.";
            that.show_message_modal= true;
            that.currentEmail = that.email;
              that.loading = false; 
        }).catch(function(error){
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
            that.modal_message = error.response.data.message;
        }); 
    },
    updateMobile() {
        const that = this;
        const cookie = that.$cookies;

        var payload = {
            hash: cookie.get('user').hash,
            value: '+63'+that.mobile_number,
        }
        that.loading = true;
        that.show_message_modal = false;
        User.editMobile(cookie.get('user').uuid,payload).then(function() {
            that.success = true;
            that.warning = false;
            that.error = false;
            that.modal_message = "Congratulations you have successfully updated your mobile number.";
            that.show_message_modal= true;
            that.currentMobile = that.mobile_number;
            that.loading = false; 
        }).catch(function(error){
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
            that.modal_message = error.response.data.message;
        }); 
    },
    updatePassword() {
        const that = this;
        const cookie = that.$cookies;
        
        var validationPayload = {
            hash: cookie.get('user').hash,
            password: that.old_password
        }
        that.loading = true;
        that.show_message_modal = false;
        User.validatePassword(validationPayload,cookie.get('user').uuid).then(function() {
               var updatingPasswordPayload = {
                    old_password:that.old_password,
                    new_password:that.new_password,
                    con_password:that.con_password,
               }
            User.changePassword(updatingPasswordPayload,cookie.get('user').uuid).then(function() {
                that.success = true;
                that.warning = false;
                that.error = false;
                that.modal_message = "Congratulations you have successfully updated your password.";
                that.show_message_modal= true;
                that.loading = false;
            }).catch(function(error) {
                  if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
                that.error = true;
                that.warning = false;
                that.success = false;
                that.loading = false;
                that.show_message_modal= true;
            });
        }).catch(function(error){
              if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        }); 
    },
    deactivateAccount() {
        const that = this;
        const cookie = that.$cookies;
        // console.log(that.deac);
        var validationPayload = {
            hash: cookie.get('user').hash,
            password: that.deac
        }
        that.loading = true;
        that.show_message_modal = false;

        User.validatePassword(validationPayload,cookie.get('user').uuid).then(function() {
               that.requestUpdateOtp();
        }).catch(function(error){
              if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        }); 
    },
    triggerDeactivate(){
        const that = this;
        const cookie = that.$cookies;

         that.loading = true;
         User.deactivate(cookie.get('user').uuid).then(function() {
                that.success = true;
                that.warning = false;
                that.error = false;
                that.modal_message = "You have successfully deactivated your account.";
                that.show_message_modal = true;
                that.loading = false;
            }).catch(function(error) {
                    if(error.response.status == 403){
                        that.expired = true;
                        that.modal_message = "Please Log in again.";
                    }else{
                        that.modal_message = error.response.data.message;
                    }
                    that.error = true;
                    that.warning = false;
                    that.success = false;
                    that.loading = false;
                    that.show_message_modal= true;
            });
    },
    showUpdateMobileView() { 
        if(this.showUpdateMobile == false) {
            this.showUpdateMobile = true;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    showUpdatePasswordView() {
        if(this.showPasswordUpdateView == false ) { 
            this.showPasswordUpdateView = true;
            this.showUpdateMobile = false;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showSecurityView = false;
             this.showUpdateTin = false;
        }
    },
    showUpdateTinView(){
        if(this.showUpdateTin == false ) { 
            this.showUpdateTin = true;
            this.showPasswordUpdateView = false;
            this.showUpdateMobile = false;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showSecurityView = false;
        }
    },
    showSecurity() {
         if(this.showSecurityView == false ) { 
            this.showUpdateMobile = false;
            this.showUpdateUsername = false;
            this.showUpdateEmail = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = true;
            this.showUpdateTin = false;
        }
    },
    showAccountView() {
        if(this.showAccountInfoView == false ) { 
            this.showPasswordUpdateView = false;
            this.showAccountInfoView = true;
            this.showSecurityView = false;
            this.showPasswordUpdateView = false;
            this.showSecurityView = false;
            this.showUpdateTin = false;
        }
    },
    showMenu() {
        if(this.show_menu){
            this.hideMenu();
        } else {
            this.show_menu = true;
        }
    },
    hideMenu(){
        this.show_menu = false;
    },
    changed(e){
        let key = e.key;
        let that = this;
        that[key] = e.value;
        if(key =='mobile_number') {
            that.checkChange({value:e.value,type:1});
        }
        if(key =='email') {
            that.checkChange({value:e.value,type:2});
        }
    },
    checkChange(e) {
        if(e.type == 1){
            if(e.value != this.currentMobile){
                this.enableMobile = true;
            }else{
                this.enableMobile = false;
            }
        }
        if(e.type == 2){
            if(e.value != this.currentEmail){
                this.enableEmail = true;
            }else{
                this.enableEmail = false;
            }
        }
    },

    otpUpdate(e) {
      let that = this;
      that.otp = e.value;
    },
    downloadQR() {
          let url = this.getQr();
         axios.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'image/png' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'QR';
                link.click();
                URL.revokeObjectURL(link.href);
            })
    },
    resendOtp() {
        let that = this;
        that.loading = true;
        that.show_resend_otp = false;
        that.timerEnabled= true;
        that.timerCount = 120;
        let payload;
        if(that.otpType == '6'){
            payload =  {
              type:that.otpType, 
              email:that.email,
              name :that.username, 
              pin:that.pin,
            }
        }
         if(that.otpType == '7'){
            payload =  {
              type:that.otpType, 
              mobile:'+63'+that.mobile_number,
              name :that.username, 
              pin:that.pin,
            }
        }
        if(that.otpType == '3' || that.otpType == '4') {
             payload = {
                type: that.otpType,
                email: that.email,
                mobile:that.mobile_number,
                name: that.username,
            }
        }
        OTP.requestOtp(payload).then(function(response) {
              that.pin = response.data.data.pin;
              that.loading= false;
              that.show_otp_modal = true;
          }).catch(function() {
              that.loading= false;
          });
      },
    requestUpdateOtp() {
        var payload = {}
        const that = this;
        that.show_message_modal = false;
        that.show_resend_otp = false;
        that.timerEnabled= true;
        that.timerCount = 120;

        if(that.otpType == '6') {
            payload = {
                type: that.otpType,
                email: that.email,
                name: that.username,
            }
        }
        if(that.otpType == '7') {
             payload = {
                type: that.otpType,
                mobile:'+63'+that.mobile_number,
                name: that.username,
            }
        }
          if(that.otpType == '3' || that.otpType == '4') {
             payload = {
                type: that.otpType,
                email: that.email,
                mobile:that.mobile_number,
                name: that.username,
            }
        }
        that.loading = true;
        OTP.requestOtp(payload).then(function() {
            that.loading = false;
            that.show_otp_modal = true;
        }).catch(function(error){
             if(error.response.status == 403){
                that.expired = true;
                 that.modal_message = "Please Log in again.";
            }else{
                that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.warning = false;
            that.success = false;
            that.loading = false;
            that.show_message_modal= true;
        });
    },
    submitOtp() {
      let that = this;
      that.loading =true;
      let payload;
      if(that.otpType == '6') {
         payload={
            otp:that.otp,
            type:that.otpType,
            email:that.email,
        }
      }
      if(that.otpType == '7') {
        payload={
            otp:that.otp,
            type:that.otpType,
            mobile:'+63'+that.mobile_number,
        }
      }
      if(that.otpType == '3' || that.otpType == '4') {
             payload = {
                type: that.otpType,
                email: that.email,
                mobile:that.mobile_number,
                name: that.username,
                otp:that.otp,
            }
        }
     that.loading = true;
      OTP.validate(payload).then(function(){
        that.show_otp_modal= false;
        that.otp = '';
        that.verify=false;
        if(that.otpType == '6') {
            that.updateEmail();
        }
        if(that.otpType == '7'){
            that.updateMobile();
        }
        if(that.otpType == '3'){
            that.triggerDeactivate();
        }
        if(that.otpType =='4'){
            that.reactivateAccount();
        }
      }).catch(function(error) {
        that.error = true;
        that.loading = false;
        that.show_message_modal= true;
        that.modal_message = error.response.data.message;
      });
    },
    getQr() {
        let that = this;
        const cookie = that.$cookies;
        return "https://quickchart.io/qr?text=" + cookie.get('user').hash;
    },
},
watcher:{
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          this.show_resend_otp = false;
          setTimeout(() => {
            this.timerCount = this.timerCount - 1;
            // console.log(this.timerCount);
          }, 1000);
        } else {
          this.show_resend_otp = true;
          this.timerEnabled = false; // run your function here
        }
      },
      immediate: true
    },
}
})
</script>
<style scoped>
.max-width-back{
    max-width: 50px;
}
</style>
