<template>
  <ContainerView class="h-full w-full">
    <LoadingControl v-show="loading" :loading="loading"/>
     <ModalControl v-show="this.show_poll_modal" 
      v-bind:display="this.show_poll_modal" 
      :z_value="'z-50'"
      v-bind:title="lang('amendments.title')"
      @closed="closedPoll"
      @positiveClick="vote"
      @readAmendments="showAmendments"
      :positiveButton="lang('submit')"
      :amendment="lang('view.amendments')"
      :default_width="'max-w-2xl'"
      id='vote_modal'
      ref='pollModal'
      closable>
      <div class="w-full p-5 flex flex-col space-y-2 text-left">
        <span class="text-lg" v-text="lang('letter.greetings')" />
        <span class="text-lg mb-3" v-text="lang('letter.first.para')" />
        <LinkText emit_triggered='OpenTerms' @OpenTerms="show_pdf=true" class="text-theme-primary font-bold text-lg" :text="lang('view.amendments')"></LinkText>
        <span class="text-lg font-bold mt-3" v-text="lang('letter.second.para')" />
         <span class="text-lg" v-text="lang('letter.casting.vote')" />
         <span class="text-lg" v-text="lang('Very truly yours,')" />
         <img src="https://media.pafcpic.net/images/misc/paredes-signature.jpg" class="modal_max_width -ml-1 -mb-2" />
          <span class="text-lg font-bold" v-text="lang('BGEN FRANCISCO M. PAREDES (RET)')" />
          <span class="text-lg" v-text="lang('Chairperson of the Board')" />
         <div class="border border-black w-full mb-2"/>
         <span class="text-lg -mt-2" v-text="lang('letter.acknowledging.para')" />
      </div>
    </ModalControl>
    <ModalControl v-show="show_message_modal" 
      v-bind:display="show_message_modal"
      :error="this.error"
      :success="this.success"
      :note="this.warning"
      z_value="z-50"
      @positiveClick="closed()"
      :positiveButton="'OK'"
      :default_width="'max-w-xl'"
      id='vote_modal'>
      <div class="w-full p-5 flex flex-col text-xl font-bold">
          {{ this.modal_message}}
      </div>
    </ModalControl>
    <AmendmentView :display="show_pdf" @closedAmendments="closedAmendments" />
     <NavBarView :user_name="name" @slide="goto" @showMenu="showMenu"/>
      <div class="w-full h-full">
          <article ref="article1" class="bg-white flex w-full h-full p-2 place-content-center">
            <div  class="border-2 bg-white  flex flex-col shadow-md w-full rounded max-w-4xl h-fit mt-2 relative">
              <div class="w-full relative">
                  <img src="https://media.pafcpic.net/images/ad/general_assembly.jpg"  class="w-full max-w-7xl shadow-2xl rounded"/>
                  <div class="w-full flex md:absolute" v-if="!loading">
                    <div class="bg-white w-full border p-5 md:-mt-14 max-w-lg rounded m-auto flex flex-col justify-center shadow-lg">
                      <div class="text-lg block leading-4 border-b mb-5 p-2 uppercase font-bold" v-text="label"></div>
                          <div class="w-full flex justify-center">
                              <Tick v-bind:value="days.toString()" label="Day" margin />
                              <Tick v-bind:value="hour.toString()" label="Hour" margin />
                              <Tick v-bind:value="minute.toString()" label="Min" margin />
                              <Tick v-bind:value="seconds.toString()" label="Sec" />
                          </div>
                          <div class=" text-white text-center flex justify-center" v-show="true">
                            <div class="w-full max-w-sm p-4">
                              <!-- <div class="text-red-500 font-bold mb-2" v-show="this.show_timer">
                                <span v-text="'Wait (' + this.request_timer + ') Before proceeding'"/>
                              </div> -->
                              <!-- <Button class="text-lg" :class="[{'hidden':false}]" :button_text="button_text" @clicked="click" rounded :disabled="false"/>  -->
                              <Button class="text-lg" :class="[{'hidden':button_visible}]" :button_text="button_text" @clicked="click" rounded :disabled="button_visible"/>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </article>
      </div>
  </ContainerView>
</template>

<script>
import { defineComponent } from 'vue';
// import { StreamBarcodeReader } from "vue-barcode-reader";
import ContainerView from '@/components/sections/ContainerView.vue';
// import TextBoxField from "@/components/controls/TextBoxField.vue";
// import member from "@/services/Member";
import User from "@/services/User";
import Button from "@/components/controls/ButtonControl.vue";
// import Checkbox from "@/components/controls/CheckBox.vue";
// import CardView from "@/components/controls/CardView.vue";
import ModalControl from "@/components/controls/ModalControl.vue";
import AmendmentView from "@/components/sections/AmendmentView.vue";
// import Icon from "@/components/controls/Icon.vue";
import LinkText from "@/components/controls/LinkText.vue";
import LoadingControl from "@/components/controls/LoadingControl.vue";
import NavBarView from "@/components/controls/NavBarView.vue";
import Tick from "@/components/controls/TickControl.vue";
import election from "@/services/Election";
import mixin from "@/mixin";
import OTP from "@/services/OTP"
export default defineComponent({
    components:{
      ContainerView,
      NavBarView,
      // TextBoxField,
      ModalControl,
      AmendmentView,
      LoadingControl,
      // Icon,
      // StreamBarcodeReader,
      // CardView,
      Tick,
      Button,
      LinkText,
      // Checkbox
    },
    mounted(){
       let that = this;
      //  let cookie = that.$cookies;
       let store = that.$store;
       let state = store.state;
       if(state.credentials.express){
          that.$router.push('/user/settings');
        }
        if(state.credentials.deactivated){
          that.$router.push('/user/settings');
        }
      if(state.election_config.voted != null) {
          that.date_start = state.election_config.date_start;
          that.date_end = state.election_config.date_end;
          that.server_time= state.election_config.server_time;
          that.election_registered = state.election_config.election_registered;
          that.registration_start=state.election_config.registration_start;
          that.registration_end =state.election_config.registration_end;
          that.registration_started =state.election_config.registration_started;
          that.can_vote = state.election_config.can_vote;
          that.voted = state.election_config.voted;
          that.voting_ended = state.election_config.voting_ended;
          that.setSchedule();
          that.setNavbar();
          // that.setButton();
      } else {
        that.testHash();
      }
    },
     data(){
      return {
        show_poll_modal:false,
        show_message_modal:false,
        error:false,
        server_time:'',
        success:false,
        warning:false,
        modal_message:'',
        expired:false,
        show_pdf:false,
        loading:false,
        mev:false,
        name:'',
        days: 0,
        hour: 0,
        minute: 0,
        seconds: 0,
        date_start:'',
        date_end:'',
        voted: false,
        voting_ended: false,
        label:'',
        modal_type:0,
        registration_start:'',
        registration_end:'',
        registration_ended:false,
        registration_started: false,
        election_registered: false,
        can_vote: false,
        button_text:'',
        button_disabled:false,
        button_visible:false,
        button_function:0,
        show_menu:false,
      }
    },
    mixins: [
      mixin,
    ],
    methods:{
      getUserInfo() {
       let that = this;
       const cookie = that.$cookies;
       const store = that.$store;
      //  let log = store.state.logged;
       let state = store.state;
        state.hash = cookie.get('user').hash;
        User.getuserInfo(cookie.get('user')).then(function(response){
          let user_data = response.data.data;
              //election
              
              state.election_config.date_end = user_data.election.vote_end;
              state.election_config.date_start = user_data.election.vote_start;
              state.election_config.server_time = response.timestamp;
              state.election_config.election_registered = user_data.election.registered;
              state.election_config.can_vote = user_data.election.can_vote;
              state.election_config.voted = user_data.election.voted;
              state.election_config.voting_ended = user_data.election.voting_ended;
              state.election_config.registration_start=user_data.election.reg_start;
              state.election_config.registration_end= user_data.election.reg_end;
              state.election_config.registration_started= user_data.election.reg_started;
              state.election_config.registration_ended= user_data.election.reg_ended;
              //credential
              state.credentials.name = user_data.account.username;
              state.credentials.first_name=user_data.info.first_name;
              state.credentials.last_name=user_data.info.last_name;
              state.credentials.middle_name=user_data.info.middle_name;
              state.credentials.gender=user_data.info.gender;
              state.credentials.regular=user_data.info.regular;
              state.credentials.account_no = user_data.info.account_no;
              state.credentials.serial_no = user_data.info.serial_no;
              state.credentials.tin= user_data.info.tin;
              state.credentials.email = user_data.account.email;
              state.credentials.mobile = user_data.account.mobile;
              state.credentials.username = user_data.account.username;
              state.credentials.qr =user_data.qr;
              state.credentials.uuid = user_data.uuid;
              state.credentials.hash = cookie.get('user').hash;
              state.credentials.key = user_data.key;
              state.credentials.membership_date = user_data.info.membership_date;
              state.credentials.registered_date = user_data.account.registered;
              state.credentials.status = user_data.info.status;
              state.credentials.membership_type = user_data.info.membership_type;
              state.credentials.deactivated = user_data.account.deactivated;

              that.date_start = state.election_config.date_start;
              that.date_end = state.election_config.date_end;
              that.server_time= state.election_config.server_time;
              that.election_registered = state.election_config.election_registered;
              that.can_vote = state.election_config.can_vote;
              that.voted = state.election_config.voted;
              that.mev = user_data.election.mev;
              that.voting_ended = state.election_config.voting_ended;
              that.registration_start=state.election_config.registration_start;
              that.registration_end =state.election_config.registration_end;
              that.registration_started =state.election_config.registration_started;
              that.registration_ended = user_data.election.reg_ended
              that.setNavbar();
              that.setButton();
              that.setSchedule();
              state.credentials.express =user_data.account.express; 
              if(user_data.account.express){
                that.$router.push('/user/settings');
              }
              if(user_data.account.deactivated){
                that.$router.push('/user/settings');
              }
              that.loading = false;
  
        }).catch(function(error){
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            that.modal_message = error.response.data.message;
            if(error.response.status == 403) {
              that.expired = true
              that.modal_message = "Please Log in Again.";
            }
        });
      },
      testHash(){
        let that = this;
        let cookie = that.$cookies;
        that.loading = true;

        User.testHash(cookie.get('user').hash).then(function(){
           that.getUserInfo();
        }).catch(function(error){
          that.error = true;
          that.loading = false;
          that.show_message_modal = true;
          that.modal_message = error.response.data.message;
          that.expired = true
        });
      },
       goto(refName) {
        var element = this.$refs[refName];
        var top = element.offsetTop;
        this.show_menu=false;
        window.scrollTo(0, top);
      },
      remove() {
        this.show_identifier = false;
      },
      OpenTerms() {
          this.$emit('OpenTerms');
      },
      OpenPrivacy() {
        this.$emit('OpenPrivacy');
      },
      setSchedule(){
          let that = this;
          let now = new Date().getTime(that.server_time);
          let registration_start =new Date(that.registration_start).getTime();
          // let registration_end =new Date(that.registration_end).getTime();
          let registration_started =that.registration_started;

          let election_start = new Date(that.date_start).getTime();
          let election_end = new Date(that.date_end).getTime();
          if(that.voted){
            that.button_visible = true;
            if(now>election_start) {
              that.label = 'Voting Ends In';
              that.date_end = election_end;
            }
            if(now>election_end){
                that.label = 'ENDED';
                //  that.button_visible = true;
            }
          }else{
             if(that.election_registered) {
                if(now>=election_end){
                    that.label = 'ENDED';
                    that.button_visible = true;
                }
                else if(now<election_start){
                    that.label = 'Voting Starts In';
                    that.date_end = election_start;
                    that.button_visible = true;
                  
                }else if(now>=election_start) {
                    that.label = 'Voting Ends In';
                    that.button_text = "VOTE NOW";
                    that.button_function = 2;
                    that.date_end = election_end;
                  if(that.can_vote && now<election_end ){
                    that.button_visible = false;
                  }else{
                    that.button_visible = true;
                  }
              }
          } else {
                if(!registration_started){
                  if(now>election_end){
                      that.label = 'ENDED';
                      that.button_visible = true;
                  }
                 else if(now<registration_start) {
                      that.label = 'Registration Starts In';
                      that.button_visible = true;
                      that.date_end = registration_start;
                  }
                } else {
                  if(now>election_end){
                      that.label = 'ENDED';
                      that.button_visible = true;
                  }
                  else if(now>election_start) {
                      that.label = 'Voting Ends In';
                      if(!that.registration_ended){
                        that.button_visible = false;
                      }else{
                        that.button_visible = true;
                      }
                      that.date_start = election_start;
                      that.button_text = "REGISTER";
                      that.button_function = 1;
                    }else{
                       that.label = 'Voting Starts In';
                      that.button_visible = false;
                      that.date_end = election_start;
                      that.button_text = "REGISTER";
                      that.button_function = 1;
                    }
                }
              
          }
          }
          that.setClock();
          that.loading = false;
      },
      setButton(){
        let that = this;
        if(!that.voting_ended) {
          if(!that.election_registered) {
            that.button_text = "REGISTER";
            that.button_visible = false;
            that.button_function = 1;
          }
          if(that.election_registered && that.can_vote) {
            that.button_text = "VOTE NOW";
            that.button_visible = false;
            that.button_function = 2;
          }
          if(that.election_registered && !that.can_vote){
            that.button_visible = true;
          }
          if(!that.election_registered && !that.can_vote){
              that.button_text = "REGISTER";
              that.button_visible = false;
              that.button_function = 1;
          }
          if(that.voted) {
            that.button_visible = true;
          }
         }else{
          that.button_visible = true;
          that.label = "Ended";
         }
      },
      showMenu() {
        if(this.show_menu){
          this.hideMenu();
        } else {
          this.show_menu = true;
        }
      },
      hideMenu(){
        this.show_menu = false;
      },
      vote(n)
      {
        let that= this;
        const cookie = that.$cookies;
        let payload = {
            vote:n,
        }
        that.button_visible = false;
        that.voted = true;
        that.loading = true;
        that.show_poll_modal = false;
        election.pollVote(cookie.get('user').uuid,payload).then(function() {
            that.success = true;
            that.modal_message = 'Your vote has been successfully cast. Thank you for participating in our Special General Assembly';
            that.show_message_modal = true;
            that.loading= false;
            that.setButton();
        }).catch(function(error) {
            if(error.response.status == 403){
              that.expired = true;
              that.modal_message = "Please Log in Again.";
            }else{
              that.modal_message = error.response.data.message;
            }
            that.error = true;
            that.loading = false;
            that.show_message_modal = true;
            
        });
        
      },
      click() {
        let that= this;
        // const cookie = that.$cookies;
        // console.log(that.button_function);
        if(that.button_function == 1 ){
            that.register();
        }
        if(that.button_function == 2){
          // that.showPollModal();
          that.loading = true;
          that.$router.push("/election/voting");
          
          // that.setButton();
        }
        
      },
      requestOtp() {
            let that = this;
            const store = that.$store;
            const state = store.state;
            var payload = {
                email:state.credentials.email,
                mobile:state.credentials.mobile,
                name:state.credentials.first_name,
                pin:state.credentials.pin,
                type:5
            }
            that.loading = true;
             OTP.requestOtp(payload).then(function(response) {
                var request_otp = response.data.data;
                state.credentials.otp = request_otp.otp;
                state.credentials.pin = request_otp.pin;
                that.loading = false;
            }).catch(function(response) {
                that.loading = false;
                that.button_visible = true;
                that.voted = true;
                that.error = true;
                that.show_message_modal = true;
                that.modal_message = response.data.message;
            });
           
        },
      register(){
        let that= this;
        const cookie = that.$cookies;
        const store = that.$store;
       let state = store.state;
        that.loading = true;
        election.register(cookie.get('user').uuid).then(function(response) {
              that.loading = false;
              if(that.can_vote) {
                  state.election_config.election_registered = true;
                  that.election_registered = true;
                // that.show_poll_modal = true;
                  that.success = true;
                  that.show_message_modal = true;
                  that.modal_message = response.data.data.message;
                  that.setSchedule();
                  if(response.data.data.status == 0) {
                    that.requestOtp();
                  }
               } else {
                that.button_visible = true;
                that.voted = true;
                that.success = true;
                that.show_message_modal = true;
                that.modal_message = "Thank you! for Registering";
              }
        }).catch(function(error) {
            if(error.response.status == 403) {
              that.expired = true
              that.modal_message = "Please Log in Again";
            }else{
              that.modal_message = error.response.data.message;
            }
              that.loading = false;
              that.error = true;
              that.show_message_modal = true;
              
              that.modal_type = 0;
        });
      },
      setNavbar(){
            let that = this;
            const store = that.$store;
            let state = store.state.credentials;
            
            that.name = state.name;
      },
      showAmendments() {
         this.show_pdf = true;
      },
      showPollModal(){
        this.show_poll_modal = true;
      },
      closedPoll(){
        this.show_poll_modal = false;
      },
      closed(){
        let that = this;
        let store = that.$store;
        let state = store.state;
        if(that.expired) {
          store.state.credentials.hash = '';
          state.logged = false;
          that.$cookies.remove('user');
          that.expired = false;
          // console.log(store);
          that.$router.push('/')
        }
        this.show_message_modal = false;
      },
      closedAmendments(){
        this.show_pdf = false;
      },
      closePdf() {
        this.show_pdf = false;
      },
      setClock() {
      const that = this;
      
      let t = setInterval(function () {
        const route = that.$route.path.toLowerCase();

        if(route == '/dashboard')
        {
          let now = new Date().getTime(that.server_time);
          let date = new Date(that.date_end).getTime();
          let delta = date - now;

          that.days = Math.floor(delta / (1000 * 60 * 60 * 24));
          that.hour = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          that.minute = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((delta % (1000 * 60)) / 1000);
          
          if(that.days < 10) {
              that.days = '0'+ that.days;
          }
           if(that.hour < 10) {
              that.hour = '0'+ that.hour;
          }
           if(that.minute < 10) {
              that.minute = '0'+ that.minute;
          }

          if(seconds == 60) 
          {
            seconds = 0;
          }
          if(seconds < 10) {

            that.seconds = '0' + seconds;

          } else {

            that.seconds = seconds;

          }
          if(that.days == '00' && that.hours == '00' && that.minutes == '00' && that.seconds == '00') {
              
              if(that.voting_ended) {
                that.days = '00';
                that.hour = '00';
                that.minute = '00';
                that.seconds = '00';
              }
          }
          if(seconds < 0) {
              that.days = '00';
              that.hour = '00';
              that.minute = '00';
              that.seconds = '00';
              that.setSchedule();
              // that.setButton();
              clearInterval(t);
          }
          else if(seconds == 0){
            that.setSchedule();
            // that.setButton();
            clearInterval(t);
          }
        }
      }, 1000);
    },
    },
   
})
</script>
<style scoped>
.modal_max_width{
  max-width: 200px;
}
.contact-w {
    width: 100px !important;
    min-width: 120px !important;
}
.strip-banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.timer{
  margin: auto !important;
}
/* .margin-top-menu {
  margin-top: 73px;
} */
</style>
